import {useMemo, type FC} from "react"
import {Link, useParams} from "react-router-dom"
import {Box, Skeleton, Stack, useTheme} from "@mui/material"
import {TextWithTooltip} from "../textWithTooltip"

type Link = {
  label: string
  href: string
  loading?: boolean
  active?: boolean
}

type BreadcrumbProps = {
  links?: Link[]
}
const uppercaseWidth = 11
const lowercaseWidth = 10
const whitespaceWidth = 4
const minimumCharactersPerLink = 8

const offsetBreakpoints = {
  "320": 600,
  "500": 500,
  "900": 350,
  "1280": 300,
  "1440": 170,
  "1500": 150,
  "1600": 100,
  "1800": 0,
  "1920": -100,
  "2560": -200,
} as Record<string, number>

const Breadcrumb: FC<BreadcrumbProps> = (props) => {
  const {links} = props
  const theme = useTheme()
  const {fileId} = useParams<{
    id: string
    folderId: string
    fileId: string
  }>()
  const windowWidth = window.innerWidth
  const offset = Object.entries(offsetBreakpoints)
    .filter(([breakpoint]) => windowWidth >= parseInt(breakpoint))
    .pop()?.[1]
  const containerWidth = windowWidth - (fileId ? offset || 100 : 719)

  const whiteSpaceCount =
    links?.reduce((acc, link) => {
      return acc + link.label.split(" ").length - 1
    }, 0) || 0

  const uppercaseCount =
    links?.reduce((acc, link) => {
      return acc + (link.label.length - link.label.replace(/[A-Z]/g, "").length)
    }, 0) || 0

  const lowercaseCount =
    links?.reduce((acc, link) => {
      return acc + link.label.replace(/[A-Z]/g, "").replace(/\s/g, "").length
    }, 0) || 0

  const whistpaceWidth = whiteSpaceCount * whitespaceWidth
  const uppecaseWidth = uppercaseCount * uppercaseWidth
  const lowecaseWidth = lowercaseCount * lowercaseWidth

  const averageCharacterWidth =
    (whistpaceWidth + (uppecaseWidth + lowecaseWidth)) /
    (whiteSpaceCount + (uppercaseCount + lowercaseCount))

  const linksMaxCharacters: number[] = useMemo(() => {
    if (!links) {
      return []
    }
    let remainingCharacters = containerWidth / averageCharacterWidth
    const output: number[] = []
    const input = [...links].reverse()

    for (const link of input) {
      const labelLength = link.label.length
      let maxCharacters = 0
      if (labelLength < remainingCharacters) {
        maxCharacters = labelLength
        remainingCharacters -= maxCharacters
      } else {
        if (
          labelLength < minimumCharactersPerLink ||
          remainingCharacters < minimumCharactersPerLink
        ) {
          maxCharacters = minimumCharactersPerLink
        } else {
          maxCharacters = Math.min(labelLength, remainingCharacters)
        }
        remainingCharacters -= maxCharacters
      }
      output.push(Math.floor(maxCharacters))
    }

    output.reverse()
    return output
  }, [links, containerWidth])

  return (
    <Stack direction="row" gap="0.375rem" paddingY="0.5rem" component="nav">
      {links?.map((link, index) => (
        <Stack
          key={index}
          direction="row"
          gap="0.5rem"
          alignItems="center"
          sx={{
            a: {
              textDecoration: "none",
            },
            svg: {
              fill: theme.palette.info.dark,
              height: "0.75rem",
              width: "0.75rem",

              path: {
                fill: "inherit",
              },
            },
          }}
        >
          {link.loading ? (
            <Skeleton variant="rectangular" height="1rem" width="5rem" />
          ) : (
            <Link
              to={
                link.label.toLocaleLowerCase() === "client uploads"
                  ? links[index - 1].href || link.href
                  : link.href
              }
              style={{display: "block"}}
            >
              <TextWithTooltip
                limit={
                  index === 0
                    ? link.label.length
                    : linksMaxCharacters[index] || 16
                }
                text={
                  link.label.toLocaleLowerCase() === "client uploads"
                    ? "Your uploads"
                    : link.label
                }
                textStyle={{
                  color:
                    index === (links!!.length || 1) - 1
                      ? "#242D35"
                      : theme.palette.info.dark,
                  fontSize: "1rem",
                  lineHeight: "1.362rem",
                  fontWeight: link.active ? "700" : "400",
                  display: "box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  whiteSpace: "nowrap",
                }}
                tooltipProps={{
                  placement: "top",
                  arrow: true,
                }}
              />
            </Link>
          )}
          {index !== (links!!.length || 1) - 1 ? (
            <Box color={theme.palette.info.dark} fontSize="1rem">
              /
            </Box>
          ) : null}
        </Stack>
      ))}
    </Stack>
  )
}

Breadcrumb.displayName = "Breadcrumb"

export default Breadcrumb
