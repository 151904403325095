import {
  forwardRef,
  type FC,
  type PropsWithChildren,
  type ReactNode,
} from "react"
import {
  Button as MuiButton,
  Stack,
  type ButtonProps,
  CircularProgress,
} from "@mui/material"

type Props = {
  size?: "small" | "medium" | "large"
  color?: "primary" | "secondary" | "destructive"
  variant?: "filled" | "outline" | "underline" | "text"
  icon?: boolean
  loading?: boolean
  split?: boolean
  text?: string
  IconLeft?: ReactNode
  IconRight?: ReactNode
  splitButtonProps?: ButtonProps
} & Omit<
  ButtonProps,
  "size" | "variant" | "loading" | "startIcon" | "endIcon" | "color"
>

const defaultProps = {
  size: "medium",
  color: "primary",
  variant: "filled",
  disabled: false,
  loading: false,
  fullWidth: false,
  split: false,
} satisfies Props

type SplitContainerProps = {
  split?: boolean
}

const SplitContainer: FC<PropsWithChildren<SplitContainerProps>> = (props) => {
  const {split, children} = props
  if (!split) return children
  return (
    <Stack direction="row" width="fit-content">
      {children}
    </Stack>
  )
}

const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    size,
    text,
    color,
    variant,
    IconLeft,
    IconRight,
    disabled,
    icon,
    loading,
    fullWidth,
    split,
    splitButtonProps,
    sx,
    ...rest
  } = {
    ...defaultProps,
    ...props,
  }

  return (
    <SplitContainer split={split}>
      <MuiButton
        disabled={disabled}
        aria-label={text}
        aria-hidden={text ? "false" : "true"}
        role="button"
        disableRipple
        sx={{
          textTransform: "none",
          boxSizing: "border-box",
          fontSize: "1rem",
          fontWeight: 600,
          letterSpacing: "-1.5%",
          lineHeight: "auto",
          textAlign: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
          pointerEvents: loading ? "none" : undefined,
          cursor: loading ? "progress" : "pointer",
          borderRadius: split ? "0.25rem 0 0 0.25rem" : "0.25rem",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: variant === "outline" ? "currentColor" : "transparent",
          borderBottomColor:
            variant === "underline" ? "currentColor" : undefined,
          height:
            size === "small" ? "2rem" : size === "medium" ? "2.5rem" : "3rem",
          width: fullWidth
            ? "100%"
            : icon
            ? size === "small"
              ? "2rem"
              : size === "medium"
              ? "2.5rem"
              : "3rem"
            : "fit-content",
          minWidth: !icon && size === "large" ? "12.5rem" : undefined,
          padding: icon
            ? "0.5rem"
            : variant === "underline"
            ? "0.188rem"
            : "0 1rem",
          color:
            variant === "filled"
              ? "#FFFFFF"
              : color === "primary"
              ? "#053747"
              : color === "secondary"
              ? "#226CA6"
              : color === "destructive"
              ? "#CC0F03"
              : "#FFFFFF",
          backgroundColor:
            variant === "filled"
              ? color === "primary"
                ? "#053747"
                : color === "secondary"
                ? "#226CA6"
                : color === "destructive"
                ? "#CC0F03"
                : "#226CA6"
              : "transparent",
          transition:
            "background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out",
          "svg, img, .MuiCircularProgress-root": {
            color: "inherit",
            width: "1.5rem",
            height: "1.5rem",
          },
          "&:hover": {
            borderBottomWidth: variant === "underline" ? "2px" : undefined,
            color:
              color === "destructive" && variant !== "underline"
                ? "#FFFFFF"
                : undefined,
            backgroundColor:
              variant === "filled"
                ? color === "primary"
                  ? "#0A495D"
                  : color === "secondary"
                  ? "#0E568D"
                  : color === "destructive"
                  ? "#AF0A00"
                  : "#0A495D"
                : variant === "outline"
                ? color === "primary"
                  ? "#E6EBED"
                  : color === "secondary"
                  ? "#E2F0FC"
                  : color === "destructive"
                  ? "#AF0A00"
                  : "#E6EBED"
                : variant === "underline"
                ? "transparent"
                : variant === "text"
                ? color === "primary"
                  ? "#E1E6E8"
                  : color === "secondary"
                  ? "#E1E6E8"
                  : color === "destructive"
                  ? "#CC0F03"
                  : "#E1E6E8"
                : "transparent",
          },
          "&:active": {
            color:
              color === "destructive" && variant !== "underline"
                ? "#FFFFFF"
                : undefined,
            backgroundColor:
              variant === "filled"
                ? color === "primary"
                  ? "#0A5D81"
                  : color === "secondary"
                  ? "#0C4A7A"
                  : color === "destructive"
                  ? "#890800"
                  : "#0A5D81"
                : variant === "outline"
                ? color === "primary"
                  ? "#CDD7DA"
                  : color === "secondary"
                  ? "#DDE4E8"
                  : color === "destructive"
                  ? "#890800"
                  : "#CDD7DA"
                : variant === "underline"
                ? "transparent"
                : variant === "text"
                ? color === "primary"
                  ? "#CCD8DC"
                  : color === "secondary"
                  ? "#CCD8DC"
                  : color === "destructive"
                  ? "#890800"
                  : "#CCD8DC"
                : "transparent",
          },
          "&:disabled": loading
            ? undefined
            : {
                backgroundColor:
                  variant === "text" || variant === "underline"
                    ? "transparent"
                    : "#EEEEEE",
                color: "#CACDCD",
                borderColor:
                  variant === "outline" ? "currentColor" : "transparent",
                "svg, img, .MuiCircularProgress-root": {
                  color: "#CACDCD",
                },
              },
          ...sx,
        }}
        {...rest}
        ref={ref}
      >
        {loading ? <CircularProgress size="1.5rem" /> : null}
        {IconLeft}
        {icon && !text ? null : <span>{text}</span>}
        {!split ? IconRight : null}
      </MuiButton>
      {split ? (
        <MuiButton
          disabled={disabled}
          sx={{
            borderRadius: "0 0.25rem 0.25rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height:
              size === "small" ? "2rem" : size === "medium" ? "2.5rem" : "3rem",
            width: "fit-content",
            minWidth: "0",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: variant === "outline" ? "currentColor" : "transparent",
            borderLeftColor:
              variant === "outline"
                ? "transparent"
                : variant === "filled"
                ? "#FFFFFF"
                : "transparent",
            padding: "0.375rem",
            "svg, img": {
              color: "inherit",
              width: "1.5rem",
              height: "1.5rem",
            },
            backgroundColor:
              variant === "filled"
                ? color === "primary"
                  ? "#053747"
                  : color === "secondary"
                  ? "#226CA6"
                  : color === "destructive"
                  ? "#CC0F03"
                  : "#226CA6"
                : "transparent",
            color:
              variant === "filled"
                ? "#FFFFFF"
                : color === "primary"
                ? "#053747"
                : color === "secondary"
                ? "#226CA6"
                : color === "destructive"
                ? "#CC0F03"
                : "#FFFFFF",
            "&:hover": {
              backgroundColor:
                variant === "filled"
                  ? color === "primary"
                    ? "#0A495D"
                    : color === "secondary"
                    ? "#0E568D"
                    : color === "destructive"
                    ? "#AF0A00"
                    : "#0A495D"
                  : variant === "outline"
                  ? color === "primary"
                    ? "#E6EBED"
                    : color === "secondary"
                    ? "#E2F0FC"
                    : color === "destructive"
                    ? "#AF0A00"
                    : "#E6EBED"
                  : "transparent",
            },
            "&:active": {
              backgroundColor:
                variant === "filled"
                  ? color === "primary"
                    ? "#0A5D81"
                    : color === "secondary"
                    ? "#0C4A7A"
                    : color === "destructive"
                    ? "#890800"
                    : "#0A5D81"
                  : variant === "outline"
                  ? color === "primary"
                    ? "#CDD7DA"
                    : color === "secondary"
                    ? "#DDE4E8"
                    : color === "destructive"
                    ? "#890800"
                    : "#CDD7DA"
                  : "transparent",
            },
            "&:disabled": {
              backgroundColor:
                variant === "text" || variant === "underline"
                  ? "transparent"
                  : "#EEEEEE",
              color: "#CACDCD",
            },
            ...splitButtonProps?.sx,
          }}
          {...splitButtonProps}
        >
          {IconRight}
        </MuiButton>
      ) : null}
    </SplitContainer>
  )
})

Button.displayName = "Button"

export default Button
