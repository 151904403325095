import {useEffect, useState, type FC} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {useQuery} from "@tanstack/react-query"
import {
  Box,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import {PowerBIEmbed} from "powerbi-client-react"
import {models} from "powerbi-client"
import {projectsEndpoints} from "../../services/api/projects"
import {deliverableEndpoints} from "../../services/api/deliverables"
import {
  DeliverableModel,
  Embed,
  FolderModel,
  type ProjectAssignmentModel,
} from "../../types"
import {Breadcrumb, TableauEmbed, Tooltip} from "../../components"
import AccessDenied from "../AccessDenied/AccessDenied"
import usePageStore from "../../stores/pageStore"
import {FullScreenView} from "../../components/molecules/fullScreen/FullScreenView"
import {PresenterModeView} from "../../components/molecules/fullScreen/PresenterModeView"
import {
  CloseIcon,
  FullScreenModeIcon,
  PresentationModeIcon,
} from "../../assets/icons"
import {useConfigProvider} from "../../config"

const ViewDashboard: FC = () => {
  const {id, folderId, dashboardId} = useParams<{
    id: string
    folderId: string
    dashboardId: string
  }>()
  const navigate = useNavigate()
  const {basename} = useConfigProvider()
  // Full screen test
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
  const [isPresenterMode, setIsPresenterMode] = useState<boolean>(false)
  const handleOpenFullscreen2 = () => {
    setIsFullscreen(true)
  }
  const handleOpenFullscreen3 = () => {
    setIsPresenterMode(true)
  }
  const handleCloseFullscreen = () => {
    setIsFullscreen(false)
    setIsPresenterMode(false)
  }
  // end

  const {getUserProjectById, getFolderById} = projectsEndpoints()
  const {getDeliverable, getTableauPreview, getPowerBIPreview} =
    deliverableEndpoints()

  const projectQuery = useQuery<ProjectAssignmentModel>({
    queryKey: ["getProject", id],
    queryFn: () => getUserProjectById(id || ""),
  })

  const folderQuery = useQuery<FolderModel>({
    queryKey: ["getFolder", id, folderId],
    queryFn: () => {
      return getFolderById(id || "", folderId || "")
    },
  })

  const deliverableQuery = useQuery<DeliverableModel>({
    queryKey: ["getDeliverable", dashboardId],
    queryFn: () => getDeliverable(dashboardId || ""),
  })

  const tableauRegion = (() => {
    const deliverableUrl = deliverableQuery?.data?.deliverableUrl
    if (deliverableUrl && deliverableUrl.includes("tableau")) {
      const regex = /https:\/\/(.*)?tableau\.(.*)\.com\/(.*)/
      const match = regex.test(deliverableUrl)

      if (match) {
        return (
          deliverableUrl
            ?.split("/")
            ?.at(2)
            ?.split(".")
            ?.at(0)
            ?.split("-")
            ?.at(0) || "uk"
        )
      }
    }
    return "uk"
  })()

  const tableauPreview = useQuery<Embed>({
    queryKey: [
      "tableau",
      tableauRegion,
      id,
      folderId,
      isFullscreen,
      isPresenterMode,
    ],
    queryFn: () => {
      return getTableauPreview(tableauRegion)
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: deliverableQuery?.data?.deliverableSource === "Tableau",
  })

  const powerBIUrl = (() => {
    const deliverableUrl = deliverableQuery?.data?.deliverableUrl
    if (deliverableUrl && deliverableUrl.includes("powerbi")) {
      const regex = /https:\/\/(.*\.)?powerbi\.com\/(.*)/
      const match = regex.test(deliverableUrl)

      if (match) {
        return deliverableUrl?.split("/")
      }
    }
    return undefined
  })()

  const pbiWorkspaceId = powerBIUrl?.at(4)
  const pbiDashboardId = powerBIUrl?.at(6)

  const powerBiPreview = useQuery<Embed>({
    queryKey: [
      "powerbi",
      pbiDashboardId,
      pbiWorkspaceId,
      isFullscreen,
      isPresenterMode,
    ],
    queryFn: () =>
      getPowerBIPreview(pbiWorkspaceId || "", pbiDashboardId || ""),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled:
      deliverableQuery?.data?.deliverableSource === "Powerbi" &&
      !!pbiWorkspaceId &&
      !!pbiDashboardId,
  })

  const handleClose = () => {
    navigate(`${basename}/${id}/folders/${folderId}`, {replace: true})
  }

  const setCurrentPage = usePageStore((state) => state.setCurrentPage)
  useEffect(() => {
    setCurrentPage(deliverableQuery.data?.deliverableName || "General")
  }, [deliverableQuery.data?.deliverableName])

  if (deliverableQuery?.data?.hasOwnProperty("title")) {
    return <AccessDenied resource="deliverable" />
  }

  if (deliverableQuery?.data?.expiryDate) {
    const deliverableExpiryDate = Date.parse(
      deliverableQuery?.data?.expiryDate
        ? deliverableQuery?.data?.expiryDate
        : "",
    )
    const currentDate = Date.parse(new Date().toISOString())

    if (currentDate > deliverableExpiryDate) {
      return (
        <AccessDenied
          resource="deliverable"
          resourceName={deliverableQuery?.data?.deliverableName}
        />
      )
    }
  }

  if (
    (!deliverableQuery.data?.enabled &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending) ||
    (!projectQuery.data?.enabled &&
      !projectQuery.isLoading &&
      !projectQuery.isFetching &&
      !projectQuery.isPending) ||
    (!folderQuery.data?.isEnabled &&
      !folderQuery.isLoading &&
      !folderQuery.isFetching &&
      !folderQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }

  if (
    (folderId !== deliverableQuery.data?.folder?.folderId &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending) ||
    (id !== deliverableQuery.data?.projectAssignmentId &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }

  return (
    <Box
      component="main"
      paddingX="2rem"
      paddingTop="1rem"
      paddingBottom="3rem"
      width="100%"
    >
      <Stack direction="column" gap="0.5rem">
        <Breadcrumb
          links={[
            {
              label: "Projects",
              href: `${basename}`,
            },
            {
              label:
                projectQuery.data?.projectAssignmentDisplayName ||
                projectQuery.data?.projectAssignmentName ||
                "",
              href: `${basename}/${id}`,
              active: false,
              loading: projectQuery.isLoading,
            },
            {
              label: folderQuery.data?.folderName || "",
              href: `${basename}/${id}/folders/${folderId}`,
              active: false,
              loading: folderQuery.isLoading,
            },
            {
              label: deliverableQuery.data?.deliverableName || "",
              href: `${basename}/${id}/folders/${folderId}/dashboard/${dashboardId}`,
              active: true,
              loading: deliverableQuery.isLoading,
            },
          ]}
        />
        <Stack direction="column" gap="0.5rem" component="section">
          <Stack
            direction="row"
            gap="0.5rem"
            justifyContent="space-between"
            component="header"
          >
            {projectQuery.isLoading ? (
              <Skeleton
                variant="text"
                width="20%"
                height="2rem"
                sx={{borderRadius: "0.5rem"}}
              />
            ) : (
              <Typography
                color="#242D35"
                fontWeight="600"
                fontSize="1rem"
                lineHeight="1.25rem"
              >
                {deliverableQuery.data?.deliverableName || ""}
              </Typography>
            )}

            <Stack direction="row" gap="8px" alignItems="center">
              <Stack direction="row" gap="4px">
                <Tooltip
                  title="Full screen"
                  arrow
                  placement="bottom"
                  sx={{".MuiTooltip-tooltip": {bgcolor: "#053747"}}}
                >
                  <IconButton
                    onClick={handleOpenFullscreen2}
                    disableTouchRipple
                    sx={{
                      p: "10px",
                      borderRadius: "4px",
                      "&:hover": {
                        bgcolor: "#E6EBED",
                      },
                    }}
                  >
                    <FullScreenModeIcon
                      height="20px"
                      width="20px"
                      fill="#003D50"
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title="Presenter view"
                  arrow
                  placement="bottom"
                  sx={{".MuiTooltip-tooltip": {bgcolor: "#053747"}}}
                >
                  <IconButton
                    onClick={handleOpenFullscreen3}
                    disableTouchRipple
                    sx={{
                      p: "10px",
                      borderRadius: "4px",
                      "&:hover": {
                        bgcolor: "#E6EBED",
                      },
                    }}
                  >
                    <PresentationModeIcon
                      height="20px"
                      width="20px"
                      fill="#003D50"
                    />
                  </IconButton>
                </Tooltip>
              </Stack>

              <Divider orientation="vertical" sx={{height: "30px"}} />

              <IconButton
                onClick={handleClose}
                disableTouchRipple
                sx={{
                  p: "6px",
                  borderRadius: "4px",
                  "&:hover": {
                    bgcolor: "#E6EBED",
                  },
                }}
              >
                <CloseIcon fill="#003D50" />
              </IconButton>
            </Stack>
          </Stack>
          <Box
            component="hr"
            sx={{
              border: 0,
              borderTopWidth: "1px",
              borderTopStyle: "solid",
              borderTopColor: "#BDBDBC",
            }}
          />
          <Box
            sx={{
              height: "810px",
              backgroundColor: "#FFFFFF",
              borderRadius: "0.5rem",
              padding: "2.5rem",
              position: "relative",
            }}
          >
            {deliverableQuery.isLoading ? (
              <Skeleton variant="rectangular" height="100%" />
            ) : deliverableQuery.data?.deliverableSource === "Tableau" ? (
              tableauPreview.isLoading || tableauPreview.isFetching ? (
                <Skeleton variant="rectangular" height="100%" />
              ) : (
                <>
                  <TableauEmbed
                    src={deliverableQuery.data?.deliverableUrl}
                    token={tableauPreview.data?.token}
                    hideTabs
                    height={702}
                    toolbar="hidden"
                  />
                  <FullScreenView
                    isFullScreen={isFullscreen}
                    handleCloseFullScreen={handleCloseFullscreen}
                  >
                    <TableauEmbed
                      src={deliverableQuery.data?.deliverableUrl}
                      token={tableauPreview.data?.token}
                      hideTabs
                      height={702}
                      toolbar="hidden"
                    />
                  </FullScreenView>
                  <PresenterModeView
                    isFullScreen={isPresenterMode}
                    handleCloseFullScreen={handleCloseFullscreen}
                  >
                    <TableauEmbed
                      src={deliverableQuery.data?.deliverableUrl}
                      token={tableauPreview.data?.token}
                      hideTabs
                      toolbar="hidden"
                      height={1000}
                    />
                  </PresenterModeView>
                </>
              )
            ) : deliverableQuery.data?.deliverableSource === "Powerbi" ? (
              powerBiPreview.isLoading || powerBiPreview.isFetching ? (
                <Skeleton variant="rectangular" height="100%" />
              ) : (
                <>
                  <PowerBIEmbed
                    embedConfig={{
                      type: "report",
                      id: pbiDashboardId || "",
                      embedUrl: powerBiPreview.data?.dashboardUrlEmbed,
                      accessToken: powerBiPreview.data?.token,
                      tokenType: models.TokenType.Embed,
                      settings: {
                        panes: {
                          filters: {
                            expanded: false,
                            visible: false,
                          },
                        },
                        background: models.BackgroundType.Default,
                      },
                    }}
                    cssClassName="Embed-container"
                  />
                  <FullScreenView
                    isFullScreen={isFullscreen}
                    handleCloseFullScreen={handleCloseFullscreen}
                  >
                    <PowerBIEmbed
                      embedConfig={{
                        type: "report",
                        id: pbiDashboardId || "",
                        embedUrl: powerBiPreview.data?.dashboardUrlEmbed,
                        accessToken: powerBiPreview.data?.token,
                        tokenType: models.TokenType.Embed,
                        settings: {
                          panes: {
                            filters: {
                              expanded: false,
                              visible: false,
                            },
                          },
                          background: models.BackgroundType.Default,
                        },
                      }}
                      cssClassName="Embed-container"
                    />
                  </FullScreenView>
                  <PresenterModeView
                    isFullScreen={isPresenterMode}
                    handleCloseFullScreen={handleCloseFullscreen}
                  >
                    <PowerBIEmbed
                      embedConfig={{
                        type: "report",
                        id: pbiDashboardId || "",
                        embedUrl: powerBiPreview.data?.dashboardUrlEmbed,
                        accessToken: powerBiPreview.data?.token,
                        tokenType: models.TokenType.Embed,
                        settings: {
                          panes: {
                            filters: {
                              expanded: false,
                              visible: false,
                            },
                          },
                          background: models.BackgroundType.Default,
                        },
                      }}
                      cssClassName="Embed-container"
                    />
                  </PresenterModeView>
                </>
              )
            ) : (
              <Skeleton variant="rectangular" height="100%" />
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}

ViewDashboard.displayName = "ViewDashboard"

export default ViewDashboard
