import type {FC, ReactNode} from "react"
import {Stack} from "@mui/material"

type Props = {
  children: ReactNode
}

export const LeftPanelLayout: FC<Props> = (props) => {
  const {children} = props

  return (
    <Stack
      component="aside"
      aria-label="left-panel-layout"
      position="fixed"
      height="100%"
      direction="row"
      bgcolor="#CCD8DC"
      zIndex="20"
      sx={{
        boxShadow: "4px 4px 10px 0px #59595833",
      }}
    >
      <Stack
        paddingX="1.25rem"
        paddingY="1.5rem"
        spacing="1.5rem"
        width="236px"
        bgcolor="#FFFFFF"
      >
        {children}
      </Stack>
    </Stack>
  )
}

LeftPanelLayout.displayName = "LeftPanelLayout"

export default LeftPanelLayout
