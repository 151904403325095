import React from "react"
import {Modal, Stack} from "@mui/material"
import CloseOutlined from "@mui/icons-material/CloseOutlined"
import {NewButton} from "../../atoms"

interface FullScreenModalProps {
  children: React.ReactNode
  handleCloseFullScreen: () => void
  isFullScreen: boolean
}

export const FullScreenView: React.FC<FullScreenModalProps> = ({
  children,
  handleCloseFullScreen,
  isFullScreen,
}) => {
  return (
    <Modal open={isFullScreen} onClose={handleCloseFullScreen}>
      <Stack sx={{height: "100vh", width: "100vw"}}>
        <Stack
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            top: 0,
            left: 0,
          }}
        >
          <Stack
            position={"absolute"}
            sx={{
              zIndex: 200,
              top: "7px",
              right: "15px",
            }}
          >
            <NewButton
              icon
              variant="filled"
              color="primary"
              size="medium"
              IconLeft={<CloseOutlined height="1.5rem" width="1.5rem" />}
              onClick={handleCloseFullScreen}
              sx={{
                minWidth: "unset",
              }}
            />
          </Stack>
          {children}
        </Stack>
      </Stack>
    </Modal>
  )
}
