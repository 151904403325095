import {SxProps, Box} from "@mui/material"
import {FC, PropsWithChildren} from "react"

type ScrollbarProps = {
  maxHeight: number | string
  sx?: SxProps
}

const scrollbarStyles: SxProps = {
  pr: 1,
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
  },
}

export const BoxWithScrollbar: FC<PropsWithChildren<ScrollbarProps>> = (
  props,
) => {
  const {children, ...rest} = props

  return (
    <Box maxHeight={rest.maxHeight} sx={{...scrollbarStyles, ...rest.sx}}>
      {children}
    </Box>
  )
}
