import {Box, Stack, Typography, useTheme} from "@mui/material"
import {Fragment, type FC, useState} from "react"
import {AccessDeniedIcon} from "../../assets/icons"
import {Button, Footer, SupportModal} from "../../components"
import {useNavigate} from "react-router-dom"
import {useConfigProvider} from "../../config"

type Props = {
  resource?: string
  resourceName?: string
}

const AccessDenied: FC<Props> = (props) => {
  const {resource, resourceName} = props
  const theme = useTheme()
  const {basename} = useConfigProvider()
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate(`${basename}`, {replace: true})
  }
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false)
  const handleSupportModal = () => {
    setShowSupportModal((open) => !open)
  }

  if (resource) {
    return (
      <Box
        component="main"
        height="calc(100vh - 173px)"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{backgroundColor: "#FBFBFB"}}
      >
        <Stack
          component="section"
          direction="column"
          alignItems="center"
          padding="2rem"
          gap="1.5rem"
          width="34.875rem"
          sx={{
            backgroundColor: theme.palette.common.white,
            borderRadius: "0.5rem",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Stack
            direction="column"
            gap="1rem"
            component="header"
            alignItems="center"
          >
            <AccessDeniedIcon height="2.75rem" width="3.78125rem" />
            <Typography
              component="h1"
              fontSize="1rem"
              fontWeight={700}
              lineHeight="1.25rem"
              color="#224957"
            >
              Page not available
            </Typography>
          </Stack>
          <Typography
            component="p"
            fontSize="0.875rem"
            fontWeight={400}
            color="#4F5B67"
            textAlign="center"
          >
            The {resource} you are trying to view is not available, please&nbsp;
            <Typography
              component="span"
              fontWeight={700}
              fontSize="0.875rem"
              color="#226CA5"
              sx={{textDecoration: "underline", cursor: "pointer"}}
              onClick={handleSupportModal}
            >
              contact us
            </Typography>
            &nbsp;if you require access.
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            component="footer"
          >
            <Button
              onClick={handleNavigate}
              sx={{
                textTransform: "none",
                color: theme.palette.common.white,
                backgroundColor: "#053747",
                paddingX: "1.5rem",
                fontWeight: 600,
                fontSize: "0.875rem",
                lineHeight: "1.5rem",

                "&:hover": {
                  backgroundColor: "#053747",
                },
              }}
            >
              Go back to homepage
            </Button>
          </Stack>
        </Stack>
        <SupportModal
          show={showSupportModal}
          handleToggle={handleSupportModal}
          title="New client support request"
          relatesToText={resourceName ? resourceName : undefined}
        />
      </Box>
    )
  }
  return (
    <Fragment>
      <Box
        component="main"
        height="calc(100vh - 173px)"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{backgroundColor: "#FBFBFB"}}
      >
        <Stack
          component="section"
          direction="column"
          alignItems="center"
          padding="2rem"
          gap="1.5rem"
          width="34.875rem"
          sx={{
            backgroundColor: theme.palette.common.white,
            borderRadius: "0.5rem",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Stack
            direction="column"
            gap="1rem"
            component="header"
            alignItems="center"
          >
            <AccessDeniedIcon height="2.75rem" width="3.78125rem" />
            <Typography
              component="h1"
              fontSize="1rem"
              fontWeight={700}
              lineHeight="1.25rem"
              color="#224957"
            >
              Access denied
            </Typography>
          </Stack>
          <Typography
            component="p"
            fontSize="0.875rem"
            fontWeight={400}
            color="#4F5B67"
            textAlign="center"
          >
            You do not have the necessary permissions to access this
            application.
            <br />
            Please contact us if you require access.
          </Typography>
        </Stack>
      </Box>
      <Footer sx={{px: "1rem"}} />
    </Fragment>
  )
}

export default AccessDenied
