import {Close} from "@mui/icons-material"
import {IconButton} from "@mui/material"
import {type FC} from "react"
import {ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import {CloseButtonProps} from "react-toastify/dist/components"

const CloseButton: FC<CloseButtonProps> = (props) => {
  const {closeToast} = props

  return (
    <IconButton
      onClick={closeToast}
      color="inherit"
      aria-label="close"
      sx={{
        height: "1.25rem",
        width: "1.25rem",
        fontSize: "0.75rem",
        padding: "0",
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: "0.75rem",
        marginRight: "0.75rem",
        backgroundColor: "transparent",

        "&:hover": {
          backgroundColor: "transparent",
        },

        ".MuiSvgIcon-root": {
          height: "1.25rem",
          width: "1.25rem",
        },
      }}
    >
      <Close htmlColor="#7E8081" />
    </IconButton>
  )
}
const Toast: FC = () => {
  return (
    <ToastContainer
      hideProgressBar={true}
      theme="light"
      style={{minWidth: "29.5rem"}}
      className="toast-container"
      toastClassName="toast"
      bodyClassName="toast-body"
      position="top-right"
      draggable={false}
      newestOnTop={true}
      closeButton={CloseButton}
      limit={5}
    />
  )
}

export default Toast
