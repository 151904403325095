import {Button, Modal, Stack, Typography} from "@mui/material"
import {FC} from "react"
import CloseIcon from "@mui/icons-material/Close"
import type {UserModel} from "../../../../types"
import UserAccessList from "../../../molecules/userAccessList/UserAccessList"

type AccessListModalProps = {
  openAccessModal: boolean
  setOpenAccessModal?: any
  isLoading?: boolean
  usersToList?: UserModel[]
}

const AccessListModal: FC<AccessListModalProps> = ({
  openAccessModal,
  setOpenAccessModal,
  isLoading,
  usersToList,
}) => {
  const handleClose = () => setOpenAccessModal(false)
  const style = {
    position: "absolute",
    top: "40vh",
    left: "10vw",
    minWidth: "350px",
    minHeight: "440px",
    borderRadius: "8px",
    padding: "14px",
    backgroundColor: "white",
    border: "1px solid #E4E4EF",
  }
  return (
    <Modal
      sx={{
        ".MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
      open={openAccessModal}
      onClose={handleClose}
      disableAutoFocus={true}
    >
      <Stack sx={style} gap={1}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={1} alignItems="center">
            <Typography fontWeight={700} fontSize={16} color={"#242D35"}>
              User access
            </Typography>
            <Typography fontWeight={400} fontSize={14} color={"#595958"}>
              {usersToList?.length} users
            </Typography>
          </Stack>
          <Button
            sx={{
              "&:hover": {
                background: "none",
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon sx={{width: "30px", height: "30px"}} />
          </Button>
        </Stack>
        <Typography fontWeight={400} fontSize={14} color={"#4F5B67"}>
          Contact Control Risks to add users
        </Typography>
        <UserAccessList
          users={usersToList ? usersToList : []}
          isLoading={isLoading}
          isFetchedAfterMount={true}
        />
      </Stack>
    </Modal>
  )
}

export default AccessListModal
