import {useConfigProvider} from "../../../config/ConfigProvider"
import {FabricEvent} from "../../types/event"

export function utilitiesEndpoints() {
  const {token, faToken, apimKey, serverUrl} = useConfigProvider()

  const headers = {
    "Content-Type": "application/json",
    "X-Functions-Key": faToken || "",
    "Ocp-Apim-Subscription-Key": apimKey || "",
    Authorization: `Bearer ${token}`,
  }

  const sendFabricEvent = async (payload: FabricEvent) => {
    const url = `${serverUrl}/utility/fabric`
    return await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    })
  }

  return {
    sendFabricEvent,
  }
}
