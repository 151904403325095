interface FooterLink {
  text: string
  link?: string
  showModal?: (setOpenModal: any) => void
}
export const footerLinks: FooterLink[] = [
  {
    text: "Legal & Privacy Policy",
    link: "https://www.controlrisks.com/legal-1/privacy-policy",
  },

  {
    text: "Grievance Process",
    link: "https://www.controlrisks.com/who-we-are/grievance-process",
  },

  {
    text: "Trust Centre",
    link: "https://www.controlrisks.com/legal-1/trust-centre",
  },

  {
    text: "Terms & Conditions",
    showModal: (setOpenModal) => setOpenModal(true),
  },

  {text: "controlrisks.com", link: "https://www.controlrisks.com/"},
]
