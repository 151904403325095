import type {FC, ReactNode} from "react"
import {Grid, Skeleton} from "@mui/material"
import {ProjectAssignmentModel} from "../../../types/ProjectAssignmentModel"
import {ProjectGridCard} from "./ProjectGridCard"

type Props = {
  projects: ProjectAssignmentModel[]
  isLoading: boolean
  isFetching?: boolean
  LazyLoadingIndicator?: ReactNode
}

export const ProjectGridView: FC<Props> = (props) => {
  const {projects, isLoading, isFetching, LazyLoadingIndicator} = props
  return (
    <Grid container rowSpacing={1} columnSpacing={1.5}>
      {isLoading && (
        <>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
            <Grid key={item} item xs={6}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height="174px"
                sx={{borderRadius: "0.5rem"}}
              />
            </Grid>
          ))}
        </>
      )}
      {!isLoading &&
        projects &&
        projects.map((project) => (
          <Grid
            item
            display="flex"
            xs={6}
            md={6}
            lg={6}
            zeroMinWidth
            key={project.id}
          >
            <ProjectGridCard key={project.id} project={project} />
          </Grid>
        ))}
      {isFetching
        ? [1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
            <Grid key={item} item xs={6}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height="174px"
                sx={{borderRadius: "0.5rem"}}
              />
            </Grid>
          ))
        : null}
      {LazyLoadingIndicator}
    </Grid>
  )
}

ProjectGridView.displayName = "ProjectGridView"

export default ProjectGridView
