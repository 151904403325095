import {FC, useState} from "react"
import {Drawer, Stack, Typography, Button, Modal} from "@mui/material"
import {CancelIcon, QuestionIcon} from "../../../assets/icons"
import {Tooltip} from "../../atoms"
import Dropzone from "../../molecules/dropzone/Dropzone"
import {ClientModel, PagedResult, ProjectAssignmentModel} from "../../../types"
import {UseQueryResult} from "@tanstack/react-query"

interface CancelModalProps {
  openCancelModal: boolean
  handleCancelModalClose: () => void
  confirmCancellationHandler: () => void
}

const CancelModal: FC<CancelModalProps> = ({
  openCancelModal,
  handleCancelModalClose,
  confirmCancellationHandler,
}: CancelModalProps) => {
  return (
    <Modal
      sx={{
        height: "232px",
        width: "704px",
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        borderTop: "7px solid #053747",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      open={openCancelModal}
      onClose={handleCancelModalClose}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0)", // adjust the color and opacity as needed
        },
        onClick: () => {
          return
        }, // do nothing
      }}
    >
      <Stack>
        <Stack
          direction="row"
          sx={{padding: "24px"}}
          alignContent={"center"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color={"#242D35"} fontSize={20} fontWeight={600}>
            Cancel file uploads
          </Typography>
          <Stack onClick={handleCancelModalClose} sx={{cursor: "pointer"}}>
            <CancelIcon fill={"#6A6A69"} />
          </Stack>
        </Stack>
        <Stack sx={{border: "1px solid #DEDEDE", width: "100%"}}></Stack>
        <Stack
          direction="row"
          sx={{padding: "24px"}}
          alignContent={"center"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color={"#242D35"} fontSize={16} fontWeight={400}>
            All progress will be lost if you cancel now. Confirm you want to
            proceed? 
          </Typography>
        </Stack>
        <Stack
          sx={{padding: "24px"}}
          spacing={1}
          direction="row"
          justifyContent={"flex-end"}
        >
          <Button
            sx={{
              textTransform: "none",
              minWidth: "120px",
              backgroundColor: "white",
            }}
            onClick={handleCancelModalClose}
          >
            <Typography
              sx={{borderBottom: "1px solid #053747", color: "#053747"}}
            >
              Cancel
            </Typography>
          </Button>
          <Button
            sx={{
              minWidth: "145px",
              textTransform: "none",
              backgroundColor: "#053747",
            }}
            onClick={() => {
              confirmCancellationHandler()
            }}
          >
            <Typography sx={{color: "white"}}>Confirm</Typography>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

interface DrawerProps {
  openDrawer: boolean
  onClose: () => void
  client?: PagedResult<ClientModel>
  project?: UseQueryResult<ProjectAssignmentModel, Error>
}

const UploadFileDrawer: FC<DrawerProps> = ({
  openDrawer,
  onClose,
  client,
  project,
}: DrawerProps) => {
  const [files, setFiles] = useState<File[]>([])
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false)
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false)

  const handleCancelModalClose = () => {
    setOpenCancelModal(false)
  }

  const handleConfirmCancellation = () => {
    setFiles([])
    setOpenCancelModal(false)
    onClose()
  }
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openDrawer}
      onClose={() => false}
      PaperProps={{
        style: {
          width: 620,
          height: "calc(100% - 144px)",
          transform: `translateX(${openDrawer ? 0 : -240}px)`,
          transition: "transform 0.3s ease-in-out",
          backgroundColor: "white",
          marginTop: "88px",
        },
      }}
    >
      <CancelModal
        openCancelModal={openCancelModal}
        handleCancelModalClose={handleCancelModalClose}
        confirmCancellationHandler={handleConfirmCancellation}
      />

      <Stack height="100%" flexGrow={1}>
        <Stack height="100%">
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            alignContent={"center"}
            sx={{padding: "16px"}}
          >
            <Typography fontWeight={600} fontSize={20} color={"#053747"}>
              Upload files {"("}
              {files?.length > 0 ? files.length : 0}/10{")"}
            </Typography>
            <Stack
              sx={{cursor: "pointer"}}
              onClick={() =>
                uploadInProgress ? null : setOpenCancelModal(true)
              }
            >
              <CancelIcon fill={"#7A7A79"} />
            </Stack>
          </Stack>

          <Stack sx={{width: "100%", border: "1px solid #DEDEDE"}}></Stack>

          <Stack
            sx={{
              padding: "16px",
              height: "100%",
            }}
            spacing={1}
          >
            <Stack
              direction="row"
              alignContent={"center"}
              alignItems={"center"}
              sx={{paddingLeft: "8px", paddingRight: "8px"}}
            >
              <Typography fontWeight={400} fontSize={16} color="#053747">
                Select files {"(up to 10 files at a time)"}
              </Typography>
              <Tooltip
                arrow={true}
                title={
                  "Supported formats: common image formats, Office documents, PDF documents. Max file size 35MB"
                }
              >
                <Stack>
                  <QuestionIcon fill={"#6A6A69"} width={24} height={24} />
                </Stack>
              </Tooltip>
            </Stack>
            <Stack sx={{height: "100%"}}>
              <Dropzone
                files={files}
                setFiles={setFiles}
                setOpenCancelModal={setOpenCancelModal}
                client={client}
                projectQuery={project}
                forceCloseDrawer={handleConfirmCancellation}
                uploadInProgress={uploadInProgress}
                setUploadInProgress={setUploadInProgress}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default UploadFileDrawer
