import {create} from "zustand"

interface PageState {
  currentPage: string
  setCurrentPage: (page: string) => void
}

const usePageStore = create<PageState>((set) => ({
  currentPage: "General",
  setCurrentPage: (page: string) => set({currentPage: page}),
}))

export default usePageStore
