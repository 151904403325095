import {Button, Typography} from "@mui/material"
import {FC} from "react"
import {ReactSVG} from "react-svg"

type UserAccessButtonProps = {
  disabled?: boolean
  openAccessModal?: boolean
  setOpenAccessModal?: any
}

export const UserAccessButton: FC<UserAccessButtonProps> = ({
  disabled,
  openAccessModal,
  setOpenAccessModal,
}) => {
  return (
    <Button
      disabled={disabled}
      variant="text"
      color="primary"
      size="small"
      sx={{
        width: "fit-content",
        textTransform: "none",
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
      startIcon={
        <ReactSVG
          fill={!disabled ? "#003D50" : "#7E8081"}
          src="/icons/user-access.svg"
        />
      }
      onClick={() => setOpenAccessModal(!openAccessModal)}
    >
      <Typography fontSize={14} fontWeight={700}>
        User access
      </Typography>
    </Button>
  )
}
