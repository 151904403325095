import {ThemeProvider} from "@mui/material/styles"
import "./index.css"
import theme from "./theme/Theme"
import App from "./App"
import {ConfigProvider} from "./config/ConfigProvider"
import {create} from "zustand"
import {decodeToken} from "react-jwt"
import {LocalizationProvider} from "@mui/x-date-pickers"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"

interface TokenState {
  token: string
  setToken: (token: string) => void
  layoutObject: {
    name: string
    title: string
    path: string
  }[]
}

export interface AccountPanelState {
  showAccountPanel: boolean
  setShowAccountPanel: (showAccountPanel: boolean) => void
}

export default function Root(props: any) {
  const {authStore, accountPanelStore} = props
  const useAuthStore = create<TokenState>(authStore)
  const useAccountPanelStore = create<AccountPanelState>(accountPanelStore)
  const token = useAuthStore((state) => state.token)
  const layoutObject = useAuthStore((state) => state.layoutObject)
  const basename =
    layoutObject?.find((item) => item.name.includes("deliverables-app"))
      ?.path || ""

  const user: {
    userId: string
    userName: string
    email: string
    firstname: string
    lastname: string
  } = decodeToken<any>(token)
    ? {
        userId: decodeToken<any>(token).sub,
        userName: decodeToken<any>(token).name,
        email: decodeToken<any>(token).email,
        firstname: decodeToken<any>(token).firstname,
        lastname:
          decodeToken<any>(token).lastname ||
          decodeToken<any>(token).surname ||
          "",
      }
    : {
        userId: "",
        userName: "",
        email: "",
        firstname: "",
        lastname: "",
      }

  if (token) {
    return (
      <ThemeProvider theme={theme()}>
        <ConfigProvider
          value={{
            token,
            user,
            basename: basename === "/" ? "/projects" : basename,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {token === "null" ? null : (
              <App
                useAccountPanelStore={useAccountPanelStore}
                basename={basename}
              />
            )}
          </LocalizationProvider>
        </ConfigProvider>
      </ThemeProvider>
    )
  }
}
