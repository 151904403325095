import {ApplicationInsights} from "@microsoft/applicationinsights-web"
import {ReactPlugin} from "@microsoft/applicationinsights-react-js"
import {ClickAnalyticsPlugin} from "@microsoft/applicationinsights-clickanalytics-js"

const clickPluginInstance = new ClickAnalyticsPlugin()
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true,
  useDefaultContentNameOrId: true,
}

const reactPlugin = new ReactPlugin()

const isLocal = process.env.REACT_APP_LOCAL === "true"
const defaultConnectionString =
   "InstrumentationKey=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
  const connectionString = isLocal
  ? defaultConnectionString
  : (window as any).DeliverablesConfig.appInsightsConnectionString
const appInsights = new ApplicationInsights({
  config: {
    connectionString: connectionString || defaultConnectionString,
    //@ts-ignore
    extensions: [reactPlugin, clickPluginInstance], // Extensions/plugins to be used with Application Insights (including the ReactPlugin)
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig, // Configuration for specific extensions (if applicable)
    },
    disableFetchTracking: true,
    disableExceptionTracking: false,
    disableDataLossAnalysis: false,
    disableTelemetry: false,
    disableAjaxTracking: true,
    enableAutoRouteTracking: true, // Enable automatic route tracking in a React application
    autoTrackPageVisitTime: true, // Enable tracking of page visit time
    enableCorsCorrelation: true, // Enable cross-origin resource sharing (CORS) correlation
    enableRequestHeaderTracking: true, // Enable tracking of request headers
    enableResponseHeaderTracking: true, // Enable tracking of response headers
  },
})

appInsights.loadAppInsights()
appInsights.addTelemetryInitializer((env) => {
  env.tags = env.tags || []
})

export {appInsights, reactPlugin}
