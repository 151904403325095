import type {PropsWithChildren, FC} from "react"
import {
  InputLabel,
  Select as MuiSelect,
  Stack,
  useTheme,
  Typography,
  type StackProps,
  SelectProps,
} from "@mui/material"
import {Warning} from "@mui/icons-material"
import {ChevronDownIcon} from "../../../assets/icons"

const FormGroup: FC<PropsWithChildren<StackProps>> = (props) => {
  const {children, ...rest} = props

  return (
    <Stack
      direction="column"
      gap="0.25rem"
      flex={1}
      role="group"
      component="article"
      {...rest}
    >
      {children}
    </Stack>
  )
}

type Props = {
  label?: string
  required?: boolean
  error?: string
  selectFieldProps: SelectProps
  formGroupProps?: StackProps
  disabled?: boolean
  placeholder?: string
}

const Select: FC<PropsWithChildren<Props>> = (props) => {
  const {
    label,
    required,
    error,
    selectFieldProps,
    formGroupProps,
    children,
    disabled,
    placeholder,
  } = props
  const theme = useTheme()
  return (
    <FormGroup {...formGroupProps}>
      {label ? (
        <InputLabel
          sx={{
            color: error
              ? theme.palette.error.main
              : theme.palette.primary.main,
            fontWeight: 400,
            fontSize: "0.875rem",
          }}
        >
          {label}
          {required ? (
            <span style={{color: theme.palette.error.main}}> *</span>
          ) : null}
        </InputLabel>
      ) : null}
      <Stack position={"relative"}>
        <MuiSelect
          {...selectFieldProps}
          required={false}
          disabled={disabled}
          IconComponent={ChevronDownIcon}
          sx={{
            ".MuiSelect-icon": {
              right: "20px",
            },
            ".MuiInputBase-input": {
              padding: "0.5rem",
              borderRadius: "0.25rem",
              transition: "all 0.2s ease-in-out",
            },
            ".MuiOutlinedInput-input": {
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: error
                ? theme.palette.error.main
                : theme.palette.primary.contrastText,

              "&:hover": {
                borderColor: theme.palette.grey[500],
              },

              "&:focus": {
                borderColor: theme.palette.primary.main,
              },
            },
            ".Mui-disabled": {
              color: theme.palette.info.contrastText,
              "-webkit-text-fill-color": theme.palette.info.contrastText,
              opacity: 1,

              "::placeholder": {
                color: theme.palette.info.contrastText,
                "-webkit-text-fill-color": theme.palette.info.contrastText,
              },
            },
            fieldset: {
              border: "none",
            },
            ...selectFieldProps?.sx,
          }}
        >
          {children}
        </MuiSelect>
        {!selectFieldProps.value && placeholder && (
          <Typography
            fontSize={"14px"}
            color={"#cecece"}
            sx={{position: "absolute", left: "16px", top: "8px"}}
          >
            {placeholder}
          </Typography>
        )}
      </Stack>
      {error ? (
        <Stack direction="row" alignItems="center" gap="0.3rem">
          <Warning
            sx={{
              color: theme.palette.error.main,
              height: "1rem",
              width: "1rem",
            }}
          />
          <Typography
            sx={{
              color: theme.palette.error.main,
              fontSize: "0.875rem",
              fontWeight: 400,
            }}
          >
            {error}
          </Typography>
        </Stack>
      ) : null}
    </FormGroup>
  )
}

export default Select
