import {useEffect, useState, useRef} from "react"

function Viz(props) {
  const vizRef = useRef(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
    return () => {
      setLoaded(false)
    }
  }, [loaded])

  useEffect(() => {
    if (loaded && !props.vizObj) {
      props.setVizObj(vizRef.current)
    }

    return () => {
      if (props.vizObj) {
        props.setVizObj(null)
      }
    }
  }, [props.vizObj, loaded, props])

  useEffect(() => {
    if (props.vizObj) {
      props.vizObj.addEventListener("firstinteractive", async () => {
        props.setInteractive(true)
      })

      return () =>
        props.vizObj.removeEventListener("firstinteractive", async () => {
          props.setInteractive(true)
        })
    }
  }, [props, props.vizObj])

  return (
    <div className="VizDiv" style={props.articleStyle}>
      <tableau-viz
        ref={vizRef}
        id="tableauViz"
        src={props.vizUrl}
        token={props.token}
        height={props.height}
        width={props.width}
        device={props.device}
        hide-tabs={props.hideTabs ? true : false}
      />
    </div>
  )
}

export default Viz
