import {Typography} from "@mui/material"

export function stringToAvatar(name: string) {
  let initials = ""

  if (name !== undefined) {
    const parts = name.split(" ")
    initials =
      parts.length > 1
        ? `${parts[0].substring(0, 1)}${parts[1].substring(0, 1)}`
        : `${parts[0].substring(0, 1)}`
  }

  return (
    <Typography fontSize={12} fontWeight={400}>
      {initials.toUpperCase()}
    </Typography>
  )
}
