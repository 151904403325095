import {CircularProgress, Typography} from "@mui/material"
import SearchBar from "../search/search"
import {Stack, useTheme} from "@mui/system"
import {useState} from "react"
import {UserModel} from "../../../types/UserModel"
import {UserCard} from "../../atoms/userCard/UserCard"

const UserAccessList = ({
  users,
  isLoading,
  isFetchedAfterMount,
}: UserAccessListsProps) => {
  const theme = useTheme()
  const [filters, setFilters] = useState<{searchText: string}>()

  const onFilter = (data: UserModel[]) => {
    let search = null
    if (filters) {
      search = data.filter(
        (user) =>
          user?.firstname
            ?.toLowerCase()
            .includes(filters?.searchText?.toLowerCase()) ||
          user?.surname
            ?.toLowerCase()
            .includes(filters?.searchText?.toLowerCase()) ||
          (user?.firstname + " " + user?.surname)
            ?.toLowerCase()
            .includes(filters?.searchText?.toLowerCase()) ||
          user?.emailAddress
            ?.toLowerCase()
            .includes(filters?.searchText?.toLowerCase()),
      )
      return search
    }
    return data
  }

  function getAvatarColor(index: number) {
    const defaultColors = ["#605DEC", "#EC775D", "#1F8252"]
    if (index == 0 || index % 3 == 0) {
      return defaultColors[0]
    } else if ((index + 1) % 3 !== 0) {
      return defaultColors[1]
    } else {
      return defaultColors[2]
    }
  }

  return (
    <>
      {isLoading || !isFetchedAfterMount ? (
        <Stack justifyContent="center" alignItems="center" p={10}>
          <CircularProgress />
        </Stack>
      ) : users.length === 0 ? (
        <Stack justifyContent="center" alignItems="center" minHeight="4rem">
          <Typography fontSize="0.9rem">
            No users found for this client
          </Typography>
        </Stack>
      ) : (
        <Stack sx={{maxHeight: "350px", border: "0px solid red"}} gap={2}>
          <Stack>
            <SearchBar
              placeholder={"Search by username or email address"}
              filterState={{filters, setFilters}}
              paperProps={{
                sx: {
                  width: "100%",
                  borderColor: theme.palette.secondary.dark,
                },
              }}
            />
          </Stack>
          <Stack gap={2} sx={{overflowY: "auto"}}>
            {onFilter(users).length > 0 ? (
              onFilter(users).map((user, index) => (
                <Stack key={user.userId}>
                  <UserCard user={user} avatarColor={getAvatarColor(index)} />
                </Stack>
              ))
            ) : (
              <Stack
                justifyContent="center"
                alignItems="center"
                minHeight="4rem"
              >
                <Typography fontSize="0.9rem">No matches found</Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </>
  )
}

export default UserAccessList

type UserAccessListsProps = {
  users: UserModel[]
  isLoading?: boolean
  isFetchedAfterMount: boolean
}
