import {Fragment, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {Box, IconButton, Skeleton, Stack, Typography} from "@mui/material"
import {ReactSVG} from "react-svg"
import type {
  ClientModel,
  DeliverableModel,
  FolderModel,
  PagedResult,
  ProjectAssignmentModel,
} from "../../types"
import {deliverableEndpoints} from "../../services/api/deliverables"
import {Breadcrumb, ConfirmationDialog, NewButton} from "../../components"
import {projectsEndpoints} from "../../services/api/projects"
import {ApryseFileViewer} from "../../components/molecules/fileViewer/FileViewer"
import {contentTypeMap} from "./utils"
import AccessDenied from "../AccessDenied/AccessDenied"
import usePageStore from "../../stores/pageStore"
import {BinIcon, CloseIcon} from "../../assets/icons"
import {useConfigProvider} from "../../config"
import {decodeToken} from "react-jwt"
import {Token} from "../../types/Token"
import {utilitiesEndpoints} from "../../services/api/utilities"
import {FabricEvent} from "../../services/types/event"
import {appInsights} from "../../config/appInsights"

const ViewFile = () => {
  const navigate = useNavigate()
  const {id, folderId, fileId} = useParams<{
    id: string
    folderId: string
    fileId: string
  }>()
  const {getDeliverable, downloadDeliverableFile, deleteDeliverable} =
    deliverableEndpoints()
  const {sendFabricEvent} = utilitiesEndpoints()
  const {getUserProjectById, getFolderById, getClientsByProjectId} =
    projectsEndpoints()
  const [fileUrl, setFileUrl] = useState<string>("")
  const [isViewerLoaded, setIsViewerLoaded] = useState<boolean>(false)
  const [hasFileError, setHasFileError] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const {token, basename} = useConfigProvider()
  const decoded = decodeToken<Token>(token)

  const deliverableQuery = useQuery<DeliverableModel>({
    queryKey: ["getDeliverable", fileId],
    queryFn: () => getDeliverable(fileId || ""),
  })
  const projectQuery = useQuery<ProjectAssignmentModel>({
    queryKey: ["getProject", id],
    queryFn: () => getUserProjectById(id || ""),
  })

  const clientQuery = useQuery<PagedResult<ClientModel>>({
    enabled:
      projectQuery.isFetched &&
      !projectQuery.isFetching &&
      !projectQuery.isLoading,
    queryKey: ["getClient"],
    queryFn: () => {
      return getClientsByProjectId(id ? id : "")
    },
    refetchOnWindowFocus: false,
  })

  const folderQuery = useQuery<FolderModel>({
    queryKey: ["getFolder", id, folderId],
    queryFn: () => {
      return getFolderById(id || "", folderId || "")
    },
  })

  const downloadFileQuery = useQuery<File>({
    queryKey: ["downloadFile", fileId],
    queryFn: async () => {
      const deliverableName = deliverableQuery.data?.deliverableName
      const fileName = deliverableName
        ? deliverableName
            .split("/")
            .pop()
            ?.match(/.{1,30}/g)
            ?.join("\n")
        : "defaultFileName"
      const extension = deliverableQuery.data?.deliverableSource?.toLowerCase()
      const contentType = extension
        ? contentTypeMap[extension as keyof typeof contentTypeMap]
        : null

      return (
        (await downloadDeliverableFile(
          fileId || "",
          fileName || "",
          contentType,
        )) || Promise.reject(new Error("File not found"))
      )
    },
    enabled: !deliverableQuery?.isLoading && !!deliverableQuery?.data,
  })

  const deleteDeliverableMutation = useMutation<
    DeliverableModel,
    Error,
    string
  >({
    mutationKey: ["deleteDeliverable", fileId],
    mutationFn: async (id) => await deleteDeliverable(id),
  })

  const fabricEventMutation = useMutation<any, Error, FabricEvent>({
    mutationFn: (payload) => sendFabricEvent(payload),
    mutationKey: ["sendNotification"],
  })

  useEffect(() => {
    if (downloadFileQuery.data) {
      const url = URL.createObjectURL(downloadFileQuery.data)
      setFileUrl(url)

      return () => URL.revokeObjectURL(url)
    }
  }, [downloadFileQuery.data])

  const handleDownload = async () => {
    if (fileUrl) {
      const link = document.createElement("a")
      link.href = fileUrl
      link.download = deliverableQuery.data?.deliverableName || "file"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      //send logs
      appInsights.trackEvent({
        name: ["client uploads", "your uploads"].includes(
          folderQuery.data?.folderName.toLowerCase() || "",
        )
          ? "DOWNLOAD_FILE"
          : "DOWNLOAD_DELIVERABLE",
        properties: {
          id: deliverableQuery.data?.id,
          projectName:
            projectQuery.data?.projectAssignmentName ??
            projectQuery.data?.projectAssignmentDisplayName,
          projectId: projectQuery.data?.id,
          fileName: deliverableQuery.data?.deliverableName,
        },
      })
      // send event to fabric
      const eventPayload = {
        type: ["client uploads", "your uploads"].includes(
          folderQuery.data?.folderName.toLowerCase() || "",
        )
          ? "download_file"
          : "download_deliverable",
        targetId: deliverableQuery.data?.id || "",
        targetName: deliverableQuery.data?.deliverableName || "",
      } satisfies FabricEvent
      await fabricEventMutation.mutateAsync(eventPayload)
    }
  }

  const handleCancel = () => {
    if (folderQuery.data?.folderName === "Client uploads") {
      navigate(-1)
      return
    }
    navigate(`${basename}/${id}/folders/${folderId}`)
  }

  const handleDelete = async () => {
    if (!fileId || decoded?.companyId === "001") return
    const result = await deleteDeliverableMutation.mutateAsync(fileId)
    if (result && result.id) {
      //send logs
      appInsights.trackEvent({
        name: "DELETE_FILE",
        properties: {
          id: result.id,
          projectName:
            projectQuery.data?.projectAssignmentName ??
            projectQuery.data?.projectAssignmentDisplayName,
          projectId: projectQuery.data?.id,
          fileName: result.deliverableName,
          userEmail: decoded?.email,
          clientName: clientQuery?.data?.results?.[0]?.clientName,
        },
      })
      queryClient.invalidateQueries({
        queryKey: ["deliverables", fileId],
        exact: true,
      })
      queryClient.invalidateQueries({
        queryKey: ["getFoldersList", fileId],
        exact: true,
      })
      setShowDeleteDialog(false)
      handleCancel()
    }
  }

  const setCurrentPage = usePageStore((state) => state.setCurrentPage)
  useEffect(() => {
    setCurrentPage(deliverableQuery.data?.deliverableName || "General")
  }, [deliverableQuery.data?.deliverableName])

  if (deliverableQuery?.data?.hasOwnProperty("title")) {
    return <AccessDenied resource="deliverable" />
  }

  const deliverableExpiryDate = Date.parse(
    deliverableQuery?.data?.expiryDate
      ? deliverableQuery?.data?.expiryDate
      : "",
  )
  const currentDate = Date.parse(new Date().toISOString())

  if (
    deliverableQuery?.data?.expiryDate &&
    currentDate >= deliverableExpiryDate
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }
  if (
    (!deliverableQuery.data?.enabled &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isPending &&
      !deliverableQuery.isFetching) ||
    (!projectQuery.data?.enabled &&
      !projectQuery.isLoading &&
      !projectQuery.isPending &&
      !projectQuery.isFetching) ||
    (!folderQuery.data?.isEnabled &&
      !folderQuery.isLoading &&
      !folderQuery.isPending &&
      !folderQuery.isFetching)
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }

  if (
    (folderId !== deliverableQuery.data?.folder?.folderId &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isPending &&
      !deliverableQuery.isFetching) ||
    (id !== deliverableQuery.data?.projectAssignmentId &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isPending &&
      !deliverableQuery.isFetching)
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }

  return (
    <Fragment>
      <Stack
        flexGrow={1}
        pt="1rem"
        pb="2rem"
        px="2rem"
        gap="0.5rem"
        direction="column"
      >
        <Stack alignItems="flex-start">
          <Stack>
            <Breadcrumb
              links={[
                {
                  label: "Projects",
                  href: `${basename}`,
                },
                {
                  label:
                    projectQuery?.data?.projectAssignmentDisplayName ||
                    projectQuery?.data?.projectAssignmentName ||
                    "",
                  href: `${basename}/${projectQuery?.data?.id}`,
                  loading: projectQuery?.isLoading,
                },
                {
                  label: folderQuery?.data?.folderName || "",
                  href: `${basename}/${projectQuery?.data?.id}/folders/${folderQuery?.data?.folderId}`,
                  loading: folderQuery?.isLoading,
                },
                {
                  label: deliverableQuery?.data?.deliverableName || "",
                  href: `${basename}/${projectQuery?.data?.id}/folders/${folderQuery?.data?.folderId}/deliverables/${deliverableQuery?.data?.id}`,
                  loading: deliverableQuery?.isLoading,
                  active: true,
                },
              ]}
            />
          </Stack>
        </Stack>
        {(downloadFileQuery.isFetchedAfterMount ||
          !downloadFileQuery.isLoading) &&
        (deliverableQuery.isFetchedAfterMount || !deliverableQuery.isLoading) &&
        !!deliverableQuery.data?.deliverableSource &&
        isViewerLoaded ? (
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontWeight={600}
              color="#242D35"
              fontSize="1rem"
              lineHeight="1.25rem"
            >
              {deliverableQuery.data?.deliverableName || ""}
            </Typography>
            <Stack
              direction="row"
              gap="2rem"
              alignItems="center"
              justifyContent="flex-end"
            >
              {decoded?.companyId !== "001" &&
              ["client uploads", "your uploads"].includes(
                folderQuery.data?.folderName.toLowerCase() || "",
              ) ? (
                <NewButton
                  icon
                  variant="outline"
                  color="destructive"
                  size="medium"
                  IconLeft={<BinIcon height="1.5rem" width="1.5rem" />}
                  onClick={() => setShowDeleteDialog(true)}
                  disabled={hasFileError}
                  sx={{
                    minWidth: "unset",
                    "&:hover, &:active": {
                      "svg path": {
                        fill: "#FFFFFF",
                      },
                    },
                    "div > div": {
                      width: "1.5rem",
                      height: "1.5rem",
                    },
                  }}
                />
              ) : null}
              <Stack flexDirection="row" gap="0.625rem">
                <NewButton
                  icon
                  variant="outline"
                  color="primary"
                  size="medium"
                  IconLeft={<ReactSVG src="/icons/download.svg" />}
                  onClick={handleDownload}
                  disabled={hasFileError}
                  sx={{
                    minWidth: "unset",
                    "div > div": {
                      width: "1.5rem",
                      height: "1.5rem",

                      svg: {
                        width: "1.5rem",
                        height: "1.5rem",

                        path: {
                          fill: "#053747",
                        },
                      },
                    },
                  }}
                />
                <IconButton
                  onClick={handleCancel}
                  disableTouchRipple
                  sx={{
                    p: "6px",
                    borderRadius: "4px",
                    "&:hover": {
                      bgcolor: "#E6EBED",
                    },
                  }}
                >
                  <CloseIcon fill="#003D50" />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Skeleton variant="rounded" width="300px" height="40px" />
            <Stack flexDirection="row" gap={1}>
              <Skeleton variant="rounded" width="49px" height="40px" />
              <Skeleton variant="rounded" width="49px" height="40px" />
            </Stack>
          </Stack>
        )}
        <Box
          component="hr"
          sx={{
            border: 0,
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            borderTopColor: "#BDBDBC",
          }}
        />
        <Stack pt={1} flexGrow={1} minHeight={"550px"}>
          {(downloadFileQuery.isFetchedAfterMount ||
            !downloadFileQuery.isFetching) &&
            (deliverableQuery.isFetchedAfterMount ||
              !deliverableQuery.isFetching) &&
            !!deliverableQuery.data?.deliverableSource &&
            !!downloadFileQuery?.data && (
              <ApryseFileViewer
                file={downloadFileQuery?.data}
                fileType={deliverableQuery.data?.deliverableSource}
                fileName={deliverableQuery.data?.deliverableName}
                setIsViewerLoaded={setIsViewerLoaded}
                error={{
                  hasFileError,
                  setHasFileError,
                }}
              />
            )}
        </Stack>
      </Stack>
      <ConfirmationDialog
        showDialog={showDeleteDialog}
        handleClose={() => setShowDeleteDialog(false)}
        handleConfirm={handleDelete}
        title="Delete file"
        message="This file will be permanently deleted. Confirm you want to proceed?"
        disabled={deleteDeliverableMutation.isPending}
      />
    </Fragment>
  )
}

export default ViewFile
