import {Warning} from "@mui/icons-material"
import {Stack, Typography, useTheme} from "@mui/material"

export const ErrorMessage = ({message}: {message: string}) => {
  const theme = useTheme()
  return (
    <Stack direction="row" alignItems="start" gap="0.3rem">
      <Warning
        sx={{
          color: theme.palette.error.main,
          height: "1rem",
          width: "1rem",
          mt: "2px",
        }}
      />
      <Typography
        sx={{
          color: theme.palette.error.main,
          fontSize: "0.875rem",
          fontWeight: 400,
        }}
      >
        {message}
      </Typography>
    </Stack>
  )
}
