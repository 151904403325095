import {useState, type FC} from "react"
import {Box, Stack, Typography, useTheme} from "@mui/material"
import {Button, SupportModal} from "../../components"
import {UnexpectedErrorIcon} from "../../assets/icons"

const Error: FC = () => {
  const theme = useTheme()
  const handleNavigate = () => {
    window.location.href = "/"
  }
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false)
  const handleSupportModal = () => {
    setShowSupportModal((open) => !open)
  }
  return (
    <Box
      component="main"
      height="calc(100vh - 173px)"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{backgroundColor: "#FBFBFB"}}
    >
      <Stack
        component="section"
        direction="column"
        alignItems="center"
        padding="2rem 1.25rem"
        gap="1.5rem"
        width="34.875rem"
        sx={{
          backgroundColor: theme.palette.common.white,
          borderRadius: "0.5rem",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack
          direction="column"
          gap="1rem"
          component="header"
          alignItems="center"
        >
          <UnexpectedErrorIcon height="2.75rem" width="3.78125rem" />
          <Typography
            component="h1"
            fontSize="1rem"
            fontWeight={700}
            lineHeight="1.25rem"
            color="#224957"
          >
            Unexpected error
          </Typography>
        </Stack>
        <Typography
          component="p"
          fontSize="0.875rem"
          fontWeight={400}
          color="#4F5B67"
          textAlign="center"
        >
          We are fixing the error. Please try refreshing your page and&nbsp;
          <Typography
            component="span"
            fontWeight={700}
            color="#226CA5"
            sx={{textDecoration: "underline", cursor: "pointer"}}
            onClick={handleSupportModal}
          >
            contact us
          </Typography>
          &nbsp;if the issue persists.
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          component="footer"
        >
          <Button
            onClick={handleNavigate}
            sx={{
              textTransform: "none",
              color: theme.palette.common.white,
              backgroundColor: "#053747",
              paddingX: "2rem",
              paddingY: "0.5rem",
              fontWeight: 600,
              fontSize: "0.875rem",
              lineHeight: "1.5rem",

              "&:hover": {
                backgroundColor: "#053747",
              },
            }}
          >
            Go back to homepage
          </Button>
        </Stack>
      </Stack>
      <SupportModal
        show={showSupportModal}
        handleToggle={handleSupportModal}
        title="New client support request"
        relatesToText={"Unexpected error"}
      />
    </Box>
  )
}

Error.displayName = "Error"

export default Error
