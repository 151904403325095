import React, {useEffect, useRef, useState} from "react"
import {
  CalloutComponent,
  CalloutTrigger,
  CalloutDropdown,
  CalloutDropdownContent,
} from "./Callout.styles"
import {CalloutProps} from "./types"

const Callout: React.FC<CalloutProps> = ({
  active = false,
  children,
  name,
  trigger,
}) => {
  const [isActive, setIsActive] = useState(active)
  const componentRef = useRef<any>(null)

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false)
    return () => {
      document.removeEventListener("click", handleClickOutside, false)
    }
  }, [])

  const handleClickOutside = (event: any) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setIsActive(false)
    }
  }

  return (
    <CalloutComponent ref={componentRef}>
      <CalloutTrigger
        aria-label={`Toggle ${name}`}
        onClick={() => setIsActive(!isActive)}
      >
        {typeof trigger === "function" ? trigger() : trigger}
      </CalloutTrigger>
      {isActive && (
        <CalloutDropdown>
          <CalloutDropdownContent>{children}</CalloutDropdownContent>
        </CalloutDropdown>
      )}
    </CalloutComponent>
  )
}

export default Callout
