import {Navigate, Outlet, createBrowserRouter} from "react-router-dom"
import {AppLayout} from "../layout"
import Home from "../pages/Home/Home"
import ViewProject from "../pages/ViewProject/ViewProject"
import ViewFolder from "../pages/ViewFolder/ViewFolder"
import ViewDashboard from "../pages/ViewDashboard/ViewDashboard"
import ViewFile from "../pages/ViewFile/ViewFile"
import Error from "../pages/Error/Error"
import {ErrorBoundary} from "../components"

export const createRouter = (basename: string) =>
  createBrowserRouter([
    {
      path: basename,
      element: (
        <ErrorBoundary>
          <AppLayout>
            <Outlet />
          </AppLayout>
        </ErrorBoundary>
      ),
      children: [
        {
          path: "",
          element: <Home />,
        },
        ...(basename === "/"
          ? [
              {
                path: "/projects",
                element: <Navigate to="/" replace />,
              },
            ]
          : []),
        {
          path: "/projects/:id",
          element: <ViewProject />,
        },
        {
          path: "/projects/:id/folders/:folderId",
          element: <ViewFolder />,
        },
        {
          path: "/projects/:id/folders/:folderId/dashboard/:dashboardId",
          element: <ViewDashboard />,
        },
        {
          path: "/projects/:id/folders/:folderId/file/:fileId",
          element: <ViewFile />,
        },
        {
          path: "error",
          element: <Error />,
        },
        {
          path: "*",
          element: <Error />,
        },
      ],
    },
    {
      path: "*",
      element: <Error />,
    },
  ])
export default createRouter
