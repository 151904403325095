export const multilineStringIsLink =
  /https?:\/\/|www\.\S+|(?<!\w)\.\S{2,}|(?<!https?:\/\/|www\.)\b\S*\.[a-zA-Z]{2,}\b/im

export const stringIsScript = /^(?!.*<script>.*?<\/script>).*$/is

export function regexValidators() {
  const alphaNumericRegex = /^[a-zA-Z0-9]*$/
  const stringIsNotLink = /^(?!.*\.\S).*[\w .,\\+]+$/
  const stringIsLink =
    /^(?!.*?https?:\/\/|www\.)(?!.*?\.(com|org|net|gov|edu|int|mil|arpa|info|biz|name|pro|aero|coop|museum|jobs|travel|[a-z]{2})($|\/))([\s\S]*)/gi
  const stringIsProjectCode = /^[0-9.]+$/
  const stringIsNotSQL = /^(?!SELECT |UPDATE |CREATE |DROP |DELETE )[\s\S]*$/i
  const stringIsScript = /^(?!.*<script>.*?<\/script>).*$/is
  return {
    alphaNumericRegex,
    stringIsNotLink,
    stringIsLink,
    stringIsScript,
    stringIsProjectCode,
    stringIsNotSQL,
  }
}
