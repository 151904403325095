import {ReactNode, type FC} from "react"
import {
  Grow,
  Stack,
  Popper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Typography,
  MenuItemProps,
  Box,
} from "@mui/material"
import {CheckIcon} from "../../../assets/icons"

type TextItem = {
  text: string
  variant?: "default" | "destructive"
  selected?: boolean
  Icon?: undefined
  items?: undefined
  section?: false
} & MenuItemProps

type IconItem = {
  text: string
  variant?: "default" | "destructive"
  selected?: boolean
  Icon: ReactNode
  items?: undefined
  section?: false
} & MenuItemProps

type SectionItem<T = TextItem[] | IconItem[]> = {
  text: string
  items: T
  onClick?: undefined
  variant?: undefined
  selected?: undefined
  Icon?: ReactNode
  section: true
}

type TextList = (SectionItem<TextItem[]> | TextItem)[]
type IconList = (SectionItem<IconItem[]> | IconItem)[]

type Props = {
  open: boolean
  anchorEl: HTMLElement | null
  handleClose: () => void
  items: TextList | IconList
}
const Popover: FC<Props> = (props) => {
  const {open, anchorEl, handleClose, items} = props
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      placement="bottom-end"
      disablePortal
      sx={{
        zIndex: 10,
      }}
    >
      {({TransitionProps, placement}) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "left top" : "left bottom",
          }}
        >
          <Stack
            sx={{
              boxSizing: "border-box",
              backgroundColor: "#FFFFFF",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#DFDFDF",
              borderRadius: "0.25rem",
              boxShadow: "0px 0px 8px 0px #0000001F, 0px 4px 8px 0px #00000024",
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList sx={{padding: 0}}>
                {items.map((item, index) => {
                  if (item.section) {
                    return (
                      <Box key={index}>
                        <Box
                          sx={{
                            boxSizing: "border-box",
                            padding: "0 1rem",
                            width: "200px",
                            height: "35px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            fontSize="0.75rem"
                            lineHeight="1rem"
                            color="#595958"
                            fontWeight="400"
                          >
                            {item.text}
                          </Typography>
                          {item.Icon}
                        </Box>
                        {item.items.map((subItem, subIndex) => {
                          const {text, Icon, sx, selected, ...rest} = subItem
                          return (
                            <MenuItem
                              key={subIndex}
                              sx={{
                                width: "200px",
                                height: "35px",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "0.5rem",
                                paddingX: "1.25rem",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                color:
                                  subItem.variant === "destructive"
                                    ? "#DB0D00"
                                    : "#242D35",
                                backgroundColor: selected
                                  ? "#DCEBF3"
                                  : "#FFFFFF",
                                "&:hover": {
                                  backgroundColor: selected
                                    ? "#DCEBF3"
                                    : "#EEEEE",
                                },
                                "&:active": {
                                  backgroundColor: selected
                                    ? "#DCEBF3"
                                    : "#DEDEDE",
                                },
                                "&:disabled": {
                                  color: "#9B9B9",
                                  backgroundColor: selected
                                    ? "#DCEBF3"
                                    : "#FFFFFF",
                                },
                                ...sx,
                              }}
                              {...rest}
                            >
                              {subItem.Icon ? subItem.Icon : null}
                              <Typography
                                fontSize="0.875rem"
                                lineHeight="1.25rem"
                                fontWeight={500}
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                              >
                                {subItem.text}
                              </Typography>
                              {subItem.selected ? (
                                <Box
                                  sx={{marginLeft: "auto", height: "1.5rem"}}
                                >
                                  <CheckIcon />
                                </Box>
                              ) : null}
                            </MenuItem>
                          )
                        })}
                      </Box>
                    )
                  }

                  const {text, Icon, sx, selected, ...rest} = item
                  return (
                    <MenuItem
                      key={index}
                      sx={{
                        width: "200px",
                        height: "35px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "0.5rem",
                        paddingX: "1rem",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color:
                          item.variant === "destructive"
                            ? "#DB0D00"
                            : "#242D35",
                        backgroundColor: selected ? "#DCEBF3" : "#FFFFFF",

                        "&:hover": {
                          backgroundColor: selected ? "#DCEBF3" : "#EEEEE",
                        },

                        "&:active": {
                          backgroundColor: selected ? "#DCEBF3" : "#DEDEDE",
                        },

                        "&:disabled": {
                          color: "#9B9B9",
                          backgroundColor: selected ? "#DCEBF3" : "#FFFFFF",
                        },
                        ...sx,
                      }}
                      {...rest}
                    >
                      {item.Icon ? item.Icon : null}
                      <Typography
                        fontSize="0.875rem"
                        lineHeight="1.25rem"
                        fontWeight={500}
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                      >
                        {item.text}
                      </Typography>
                      {selected ? (
                        <Box sx={{marginLeft: "auto", height: "1.5rem"}}>
                          <CheckIcon />
                        </Box>
                      ) : null}
                    </MenuItem>
                  )
                })}
              </MenuList>
            </ClickAwayListener>
          </Stack>
        </Grow>
      )}
    </Popper>
  )
}

Popover.displayName = "Popover"

export default Popover
