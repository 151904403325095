import {styled} from "@mui/system"

type CalloutDropdownProps = {
  width?: number
}

export const CalloutComponent = styled("div")({
  position: "relative",
  display: "inline-block",
  float: "right",
})

export const CalloutTrigger = styled("div")({
  padding: 0,
  cursor: "pointer",

  svg: {
    "pointer-events": "none",
  },

  ".highlight": {
    color: "grey",
    textDecoration: "underline",
    textDecorationColor: "currentColor",
  },
})

export const CalloutDropdown = styled("div")<CalloutDropdownProps>({
  position: "absolute",
  display: "flex",
  backgroundColor: "#fff",
  borderRadius: "4px",
  boxShadow: "0 10px 30px 0 rgba(0, 0, 0, 0.2)",
  width: "220px",
  zIndex: 100,

  "&:after": {
    position: "absolute",
    display: "block",
    backgroundColor: "#fff",
    clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
    bottom: "100%",
    left: "50%",
    transform: "rotate(0deg) translate(-50%, 1)",
    width: "11rem",
    height: "11rem",
  },

  top: "calc(100% + 12)",
  left: "50%",
  transform: "translateX(-50%)",
})

export const CalloutDropdownContent = styled("div")({
  overflow: "auto",
  width: "100%",
})
