import {forwardRef, useEffect, useLayoutEffect, useRef} from "react"
import ReactDOMServer from "react-dom/server"
import Quill from "quill"
import "quill/dist/quill.snow.css"
import "./style.css"
import {
  ListOrdered,
  ListBulleted,
  FontBold,
  FontItalic,
  FontUnderline,
} from "../../../assets/icons"
import {SxProps} from "@mui/system"
import filterEmojis from "../../../helpers/filterEmojis"

const icons: any = Quill.import("ui/icons")
icons["list"]["ordered"] = ReactDOMServer.renderToString(<ListOrdered />)
icons["list"]["bullet"] = ReactDOMServer.renderToString(<ListBulleted />)
icons["italic"] = ReactDOMServer.renderToString(<FontItalic />)
icons["bold"] = ReactDOMServer.renderToString(<FontBold />)
icons["underline"] = ReactDOMServer.renderToString(<FontUnderline />)

const QuillEditor = forwardRef(
  (
    {
      readOnly,
      defaultValue,
      onTextChange,
      onSelectionChange,
      onBlur,
      placeholder,
      editorContainerStyle,
    }: {
      readOnly: boolean
      defaultValue: any
      onTextChange: any
      onSelectionChange: any
      onBlur: any
      placeholder?: string
      editorContainerStyle?: SxProps
    },
    ref: any,
  ) => {
    const containerRef = useRef(null)
    const defaultValueRef = useRef(defaultValue)
    const onTextChangeRef = useRef(onTextChange)
    const onSelectionChangeRef = useRef(onSelectionChange)
    const onBlurRef = useRef(onBlur)

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange
      onSelectionChangeRef.current = onSelectionChange
      onBlurRef.current = onBlur
    })

    useEffect(() => {
      ref.current?.enable(!readOnly)
    }, [ref, readOnly])

    useEffect(() => {
      const container = containerRef.current as unknown as HTMLElement
      if (!container) return
      const el = container.ownerDocument.createElement("div")
      const editorContainer = container.appendChild(el)

      editorContainer.id = "editor-container"

      if (editorContainerStyle) {
        Object.assign(editorContainer.style, editorContainerStyle)
      }

      const quill = new Quill(editorContainer, {
        theme: "snow",
        modules: {
          toolbar: [
            {list: "bullet"},
            {list: "ordered"},
            "italic",
            "underline",
            "bold",
          ],
        },
        formats: ["bold", "italic", "underline", "list"],
        placeholder: placeholder,
      })

      ref.current = quill

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current)
      }

      quill.on(Quill.events.TEXT_CHANGE, (delta, oldDelta, source) => {
        const currentText = quill.getText()

        const filteredText = filterEmojis(currentText)

        if (currentText !== filteredText) {
          quill.setText(filteredText)
        }

        onTextChangeRef.current?.(filteredText, oldDelta, source)
      })

      quill.on(Quill.events.SELECTION_CHANGE, (range, oldRange, source) => {
        onSelectionChangeRef.current?.(range, oldRange, source)
        if (range === null && oldRange !== null) {
          onBlurRef.current?.()
        }
      })

      return () => {
        ref.current = null
        container.innerHTML = ""
      }
    }, [ref, placeholder])

    return <div id="quill-container" ref={containerRef}></div>
  },
)

QuillEditor.displayName = "QuillEditor"

export default QuillEditor
