const useStorage = (storageType: "local" | "session", key: string) => {
  const storage = storageType === "local" ? localStorage : sessionStorage

  const get = () => storage.getItem(key)

  const set = (data: string) => {
    storage.setItem(key, data)
  }

  const clear = () => {
    storage.removeItem(key)
  }

  return {get, set, clear}
}

export default useStorage

export enum StorageData {
  crmAccount = "crmAccount",
}
