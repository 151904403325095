import {type FC} from "react"
import {
  InputBase,
  Paper,
  useTheme,
  FormControl,
  InputAdornment,
  Box,
} from "@mui/material"
import {useDebouncedCallback} from "use-debounce"
import {type SearchProps} from "./types"
import {ReactSVG} from "react-svg"

const SearchBar: FC<SearchProps> = ({
  placeholder,
  filterState,
  style,
  paperProps,
  disabled = false,
}) => {
  const theme = useTheme()

  const handleChange = useDebouncedCallback((value: string) => {
    filterState.setFilters((prevState: any) => ({
      ...prevState,
      searchText: value.toLowerCase(),
    }))
  }, 500)
  return (
    <Box
      sx={{
        flexGrow: 1,
        ...style,
      }}
    >
      <FormControl variant="standard" sx={{width: "100%"}}>
        <Paper
          elevation={0}
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            background: "white",
            width: "100%",
            borderRadius: "0.4375rem",
            padding: "0.2rem 0.7rem",
            border: 1,
            borderColor: disabled ? "#CACDCD" : "#7E8081",
            transition: "all 0.2s ease-in-out",
            boxSizing: "border-box",
            "&:focus-within": {
              borderColor: theme.palette.action.active,
              elevation: 3,
            },
            ...(paperProps ? paperProps.sx : {}),
          }}
        >
          <InputBase
            disabled={disabled}
            sx={{
              width: "100%",
              ".MuiInputAdornment-root": {
                color: "#7E8081",
              },
              fontSize: "14px",
            }}
            onChange={({target: {value}}) => handleChange(value)}
            placeholder={placeholder}
            startAdornment={
              <InputAdornment position="start" color="#7E8081">
                <ReactSVG
                  src="/icons/search.svg"
                  wrapper="span"
                  className={`icon-wrapper ${disabled ? "opacity-50" : ""}`}
                />
              </InputAdornment>
            }
          />
        </Paper>
      </FormControl>
    </Box>
  )
}
export default SearchBar
