import {FC, useState} from "react"
import {useNavigate} from "react-router-dom"
import {ButtonBase, Stack, SxProps, Box, useTheme} from "@mui/material"
import type {ProjectAssignmentModel} from "../../../types/ProjectAssignmentModel"
import {
  Header,
  Description,
  DateCreated,
} from "../../atoms/projectsCard/ProjectCardComponents"
import {useConfigProvider} from "../../../config"

type Props = {
  project: ProjectAssignmentModel
}

export const ProjectGridCard: FC<Props> = (props) => {
  const {project} = props
  const [hover, setHover] = useState<boolean>(false)
  const theme = useTheme()
  const navigate = useNavigate()
  const {basename} = useConfigProvider()
  const cardStyles: SxProps = {
    gap: 2,
    borderRadius: "0.25rem",
    height: "174px",
    backgroundColor: theme.palette.common.white,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#EEEEEE",
    borderLeftWidth: "0.5rem",
    borderLeftColor: "#DEDEDE",
    transition: "border-color 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      cursor: "pointer",
      borderColor: "#375F6C",
      borderLeftWidth: "0.5rem",
      borderLeftColor: "#375F6C",
      "> .MuiDivider-root": {
        backgroundColor: "#668A96",
      },
    },
  }

  return (
    <Box
      flex={1}
      sx={{...cardStyles}}
      component="article"
      role="article"
      aria-label={
        project.projectAssignmentDisplayName ||
        project.projectAssignmentName ||
        ""
      }
      aria-describedby={project.projectAssignmentDescription}
    >
      <ButtonBase
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        TouchRippleProps={{
          color: "#EEEEEE",
        }}
        sx={{
          padding: 2,
          flexDirection: "column",
          textAlign: "left",
          alignItems: "flex-start",
          height: "100%",
          width: "100%",

          ".MuiTouchRipple-child": {
            backgroundColor: "#EEEEEE",
          },
        }}
        onClick={() => navigate(`${basename}/${project.id}`)}
      >
        <Stack width="100%" flex={1} justifyContent="space-between">
          <Stack flex={1} spacing={2} height="100%">
            <Header
              hover={hover}
              title={
                project.projectAssignmentDisplayName ||
                project.projectAssignmentName ||
                ""
              }
              filesCount={project.filesCount || 0}
              folderCount={project.foldersCount || 0}
              dashboardCount={project.dashboardsCount || 0}
            />
            <Description text={project.projectAssignmentDescription} />
          </Stack>
          <Stack
            width="100%"
            direction="row"
            justifyContent="flex-end"
            textAlign="right"
          >
            <DateCreated dateTime={project.createdTimestamp || ""} />
          </Stack>
        </Stack>
      </ButtonBase>
    </Box>
  )
}

ProjectGridCard.displayName = "ProjectGridCard"

export default ProjectGridCard
