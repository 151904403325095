import {Stack, Typography, useTheme} from "@mui/material"
import {AccessDeniedIcon} from "../../../assets/icons"
import {useNavigate} from "react-router-dom"
import {Button} from "../../atoms"
import {useState} from "react"
import SupportModal from "../../organisms/SupportModal/SupportModal"

type Props = {
  fileName?: string
}

export const FileError = (props: Props) => {
  const {fileName} = props
  const theme = useTheme()
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate(-1)
  }

  const [showSupportModal, setShowSupportModal] = useState<boolean>(false)
  const handleSupportModal = () => {
    setShowSupportModal((open) => !open)
  }
  return (
    <Stack
      flexGrow={1}
      sx={{
        backgroundColor: theme.palette.common.white,
        borderRadius: "0.5rem",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Stack
        component="section"
        direction="column"
        alignItems="center"
        pt="8rem"
        gap="1.5rem"
        width="100%"
      >
        <Stack
          direction="column"
          gap="1rem"
          component="header"
          alignItems="center"
        >
          <AccessDeniedIcon height="2.75rem" width="3.78125rem" />
          <Typography
            component="h1"
            fontSize="1rem"
            fontWeight={700}
            lineHeight="1.25rem"
            color="#224957"
          >
            Page not available
          </Typography>
        </Stack>
        <Typography
          component="p"
          fontSize="14px"
          fontWeight={400}
          color="#4F5B67"
          textAlign="center"
        >
          Please&nbsp;
          <Typography
            component="span"
            fontWeight={700}
            fontSize="0.875rem"
            color="#226CA5"
            sx={{textDecoration: "underline", cursor: "pointer"}}
            onClick={handleSupportModal}
          >
            contact us
          </Typography>
          &nbsp;if you require assistance.
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          component="footer"
        >
          <Button
            onClick={handleNavigate}
            sx={{
              textTransform: "none",
              color: theme.palette.common.white,
              backgroundColor: "#053747",
              paddingX: "1.5rem",
              fontWeight: 600,
              fontSize: "0.875rem",
              lineHeight: "1.5rem",

              "&:hover": {
                backgroundColor: "#053747",
              },
            }}
          >
            Go back to previous page
          </Button>
        </Stack>
      </Stack>
      <SupportModal
        show={showSupportModal}
        handleToggle={handleSupportModal}
        title="New client support request"
        relatesToText={fileName ? fileName : undefined}
      />
    </Stack>
  )
}
