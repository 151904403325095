import {Avatar, Box, FormControlLabel} from "@mui/material"
import {UserCardTypes} from "./types"
import {stringToAvatar} from "../../../helpers/stringToAvatar"
import {TextWithTooltip} from "../../molecules"

export const UserCard = ({user, avatarColor}: UserCardTypes) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box>
        <FormControlLabel
          control={
            <Avatar
              sx={{
                background: avatarColor,
                width: "1.5rem",
                height: "1.5rem",
              }}
            >
              {stringToAvatar(user.firstname + " " + user.surname)}
            </Avatar>
          }
          key={user.userId}
          label={""}
          sx={{margin: 0}}
        />
      </Box>
      <Box sx={{display: "flex", flexDirection: "column", maxWidth: "11rem"}}>
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <TextWithTooltip
            text={user.firstname + " " + user.surname}
            textStyle={{
              fontWeight: "600",
              color: "#1A1A1A",
              textWrap: "nowrap",
            }}
            limit={19}
          />
        </Box>
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <TextWithTooltip
            text={user.emailAddress}
            textStyle={{
              fontSize: "0.9rem",
              color: "#595958",
              textWrap: "nowrap",
              fontWeight:'400',
            }}
            limit={19}
          />
        </Box>
      </Box>
    </Box>
  )
}
