import {PropsWithChildren, type FC, Fragment} from "react"
import {Navigate, useLocation} from "react-router-dom"
import {withAITracking} from "@microsoft/applicationinsights-react-js"
import {AppInsightsErrorBoundary} from "@microsoft/applicationinsights-react-js"
import {reactPlugin} from "../../../config/appInsights"

const ErrorBoundary: FC<PropsWithChildren> = (props) => {
  const {children} = props
  const {pathname} = useLocation()

  return (
    <AppInsightsErrorBoundary
      key={pathname} // This is important to reset the error boundary state on route change
      appInsights={reactPlugin}
      onError={() => {
        return <Navigate to="/error" replace={true} />
      }}
    >
      <Fragment>{children}</Fragment>
    </AppInsightsErrorBoundary>
  )
}
export default withAITracking(reactPlugin, ErrorBoundary)
 
