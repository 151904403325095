import {Stack, useTheme} from "@mui/material"
import {ReactSVG} from "react-svg"
import {TextWithTooltip} from "../textWithTooltip"
import {FC} from "react"
import {DeliverableModel} from "../../../types"
import {
  getDeliverableExtension,
  iconMap,
} from "../../../helpers/getDeliverableExtension"
import {DeliverableStatus} from "../../../types/enums/DeliverableStatus"
import {useNavigate, useParams} from "react-router-dom"
import { UploadIcon } from "../../../assets/icons"
import {useConfigProvider} from "../../../config"

type Props = {
  deliverables: DeliverableModel[]
}

const DeliverablesGridView: FC<Props> = (props) => {
  const {deliverables} = props
  const {basename} = useConfigProvider()
  const theme = useTheme()
  const navigate = useNavigate()
  const {id} = useParams<{id: string}>()

  const handleNavigate = (deliverable: DeliverableModel) => {
    if (deliverable.deliverableType === "dashboard") {
      navigate(
        `${basename}/${id}/folders/${deliverable.folder.folderId}/dashboard/${deliverable.id}`,
      )
    } else {
      navigate(
        `${basename}/${id}/folders/${deliverable.folder.folderId}/file/${deliverable.id}`,
      )
    }
  }
  return (
    <Stack direction={"row"} gap="0.625rem" flexWrap="wrap" component="section">
      {deliverables.map(
        (deliverable) =>
          deliverable.status === DeliverableStatus.CLEAN && (
            <Stack
              key={deliverable.id}
              onClick={() => handleNavigate(deliverable)}
              sx={{
                width: "264px",
                borderRadius: "0.5rem",
                backgroundColor: theme.palette.common.white,
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#EEEEEE",
                transition: "all 0.2s ease-in-out",
                textDecoration: "none",
                "&:hover": {
                  borderColor: "#375F6C",
                  cursor: "pointer",
                },
              }}
            >
              <Stack
                justifyContent={"space-between"}
                direction="row"
                alignItems="center"
                alignContent="center"
                gap="0.5rem"
                sx={{
                  maxWidth: "100%",
                  padding: "1rem",
                  wordBreak: "break-word",
                  svg: {
                    width: "20px",
                    height: "20px",
                    fill: theme.palette.primary.main,
                  },
                }}
              >
                <Stack direction="row">
                  <ReactSVG
                    src={
                      deliverable.deliverableType === "dashboard"
                        ? "/icons/file-icons/dashboards.svg"
                        : "/icons" +
                          iconMap[
                            getDeliverableExtension(
                              deliverable.deliverableUrl || "",
                            )
                          ]
                    }
                  />
                  <TextWithTooltip
                    text={deliverable.deliverableName}
                    limit={20}
                    tooltipProps={{
                      placement: "right",
                      arrow: true,
                    }}
                    textStyle={{
                      fontSize: "0.875rem",
                      color: "#242D35",
                      fontWeight: 400,
                      lineHeight: "1.25rem",
                    }}
                  />
                </Stack>

                {deliverable?.folder.folderName.toLowerCase() ===
                "client uploads" ? (
                  <Stack>
                    <UploadIcon fill="#053747" height="1rem" width="1rem" />
                  </Stack>
                ) : null}
              </Stack>
            </Stack>
          ),
      )}
    </Stack>
  )
}

export default DeliverablesGridView
