import {Stack, SxProps, Typography, useTheme} from "@mui/material"
import QuillEditor from "../../atoms/quillEditor/QuillEditor"
import {FC, SyntheticEvent, useEffect, useRef, useState} from "react"
import Quill from "quill"
import {Warning} from "@mui/icons-material"

type RichTextFieldProps = {
  onChange: (value: any) => void
  onBlur: (e: SyntheticEvent) => void
  formError?: string
  label?: string
  placeholder?: string
  editorContainerStyle?: SxProps
}
export const RichTextField: FC<RichTextFieldProps> = ({
  onChange,
  onBlur,
  formError,
  placeholder,
  label,
  editorContainerStyle,
}) => {
  const theme = useTheme()
  const quillRef = useRef<Quill | null>(null)
  const [length, setLength] = useState<number>(0)

  useEffect(() => {
    if (formError) {
      const qlToolbar = document.querySelector(".ql-toolbar")
      qlToolbar?.classList.add("ql-error-toolbar")

      const qlContainer = document.querySelector(".ql-container")
      qlContainer?.classList.add("ql-error-container")
    } else {
      const qlToolbar = document.querySelector(".ql-toolbar")
      qlToolbar?.classList.remove("ql-error-toolbar")

      const qlContainer = document.querySelector(".ql-container")
      qlContainer?.classList.remove("ql-error-container")
    }
  }, [formError])

  return (
    <Stack height={"100%"} overflow={"hidden"}>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography
          color={formError ? "#DB0D00" : "#053747"}
          fontSize={"0.875rem"}
          pb={"4px"}
        >
          {label}
          <span
            style={{
              color: "#DB0D00",
              fontSize: "0.875rem",
            }}
          >
            {" "}
            *
          </span>
        </Typography>
      </Stack>
      <QuillEditor
        defaultValue={""}
        placeholder={placeholder}
        ref={quillRef}
        readOnly={false}
        onTextChange={() => {
          const quill = quillRef.current

          if (quill) {
            let l = quill.getLength() - 1
            if (l < 0) l = 0

            setLength(l)

            const htmlContent = quill.getSemanticHTML()
            const text = quill.getText().trim()
            onChange({html: htmlContent, text: text})
          }
        }}
        onSelectionChange={undefined}
        onBlur={onBlur}
        editorContainerStyle={editorContainerStyle}
      />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={"0.3rem"}
          color={"#DB0D00"}
        >
          {formError ? (
            <>
              <Warning
                sx={{
                  color: theme.palette.error.main,
                  height: "1rem",
                  width: "1rem",
                }}
              />
              <Typography
                color={theme.palette.error.main}
                fontSize={"0.875rem"}
              >
                {formError}
              </Typography>
            </>
          ) : null}
        </Stack>
        <Typography fontSize={14} color={formError ? "#DB0D00" : "#595958"}>
          {length}
          /2000
        </Typography>
      </Stack>
    </Stack>
  )
}
