import {
  useEffect,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from "react"
import {Controller, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import {
  Box,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Slider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import {useMutation} from "@tanstack/react-query"
import * as yup from "yup"
import DOMPurify from "dompurify"
import {NewButton, Input, Select, Tooltip} from "../../atoms"
import usePageStore from "../../../stores/pageStore"
import {useConfigProvider} from "../../../config"
import {AskAnExpertNotification} from "../../../services/types/notification"
import {notificationEndpoints} from "../../../services/api/notification"
import {
  CloseIcon,
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
} from "../../../assets/icons"
import {
  deliveryType,
  formatCurrency,
  generateSupportedTimeZones,
  getBrowserTimeZoneDescription,
} from "./utils"
import {RichTextField} from "../../molecules/richTextEditor/RichTextField"
import {CustomDatePicker} from "../../atoms/datePicker/DatePicker"
import {ErrorMessage} from "./ErrorMessage"
import {toast} from "react-toastify"
import {appInsights} from "../../../config/appInsights"
import {regexValidators} from "../../../helpers"
import filterEmojis from "../../../helpers/filterEmojis"
const {stringIsLink, stringIsScript, stringIsNotSQL} = regexValidators()

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>
  showModal: boolean
}

const deliveryTypesWithRequiredDate = ["presentation", "phone call"]

const schema = yup
  .object({
    DeliveryType: yup.string().required("Delivery type is required"),
    RelatesTo: yup.string().required(),
    RequestBudget: yup.string(),
    Timezone: yup.string().required(),
    Description: yup
      .string()
      .required("Description is required")
      .min(3, "Minimum 3 characters")
      .max(2000, "Maximum 2000 characters")
      .matches(stringIsLink, "Links are not allowed")
      .matches(stringIsScript, "Invalid text format")
      .matches(stringIsNotSQL, "Invalid text format"),
    BusinessPhone: yup
      .string()
      .required("Business phone is required")
      .max(50, "Maximum 50 characters")
      .matches(/^[0-9\s()+-]*$/, "Invalid text format"),
    PreferredCallDatesStart: yup.string().when("DeliveryType", {
      is: (value: string) =>
        deliveryTypesWithRequiredDate.includes(value?.toLowerCase()),
      then: (schema) =>
        schema.required("Preferred call or presentation dates are required"),
      otherwise: (schema) => schema,
    }),
    PreferredCallDatesEnd: yup.string().when("DeliveryType", {
      is: (value: string) =>
        deliveryTypesWithRequiredDate.includes(value?.toLowerCase()),
      then: (schema) =>
        schema.required("Preferred call or presentation dates are required"),
      otherwise: (schema) => schema,
    }),
  })
  .required()
type FormData = yup.InferType<typeof schema>

export const AskAnExpertModal: FC<Props> = (props) => {
  const theme = useTheme()
  const {setShowModal, showModal} = props
  const {user} = useConfigProvider()
  const currentPage = usePageStore((state) => state.currentPage)
  const [descriptionHtmlContent, setDescriptionHtmlContent] =
    useState<string>("")

  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
    watch,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      DeliveryType: "",
      RequestBudget: "1500",
    },
  })

  const {sendAskAnExpertNotificationEmail} = notificationEndpoints()

  const sendNotificationMutation = useMutation<
    any,
    Error,
    AskAnExpertNotification
  >({
    mutationFn: (payload) => sendAskAnExpertNotificationEmail(payload),
    mutationKey: ["sendNotification"],
  })

  const handleClose = () => {
    setShowModal(false)
    reset()
  }
  const onSubmit = async (data: FormData) => {
    const deliveryType = DOMPurify.sanitize(data.DeliveryType)
    const description = filterEmojis(descriptionHtmlContent)
    const notificationPayload = {
      notificationType: "AskAnExpert-ToClientAndInternal",
      relatesTo: currentPage,
      deliveryType: deliveryType,
      description: DOMPurify.sanitize(description || ""),
      user: {
        userFirstname: user.firstname,
        userEmail: user.email,
        userOwnername: user.firstname,
      },
      deliveryTimezone: data.Timezone,
      requestBudget: formatCurrency(data.RequestBudget || ""),
      preferredCallOrPresentationDates:
        data.PreferredCallDatesStart && data.PreferredCallDatesEnd
          ? `${new Date(data.PreferredCallDatesStart).toLocaleDateString(
              "en-GB",
            )} or ${new Date(data.PreferredCallDatesEnd).toLocaleDateString(
              "en-GB",
            )}`
          : "N/A",
      businessPhone: data.BusinessPhone,
    } satisfies AskAnExpertNotification

    try {
      const notificationResponse = await sendNotificationMutation.mutateAsync(
        notificationPayload,
      )

      if (!!notificationResponse.status || !!notificationResponse.statusCode) {
        toast.error(
          "An unexpected error occurred. Please try refresh your page.",
          {
            autoClose: 3000,
            icon: ErrorIcon,
            toastId: "api-error-toast",
          },
        )
        return
      }
      appInsights.trackEvent({
        name: "ASK_AN_EXPERT",
        properties: {
          userId: user.userId,
          userName: `${user.firstname} ${user.lastname}`,
          email: user.email,
          relatesTo: currentPage,
        },
      })
      toast.success("Successful! Your request has been submitted", {
        autoClose: 3000,
        style: {
          borderLeftColor: "#58A733",
        },
        icon: SuccessIcon,
      })
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  const PreferredCallDatesStart = watch("PreferredCallDatesStart")
  const PreferredCallDatesEnd = watch("PreferredCallDatesEnd")
  useEffect(() => {
    if (PreferredCallDatesStart && PreferredCallDatesEnd) {
      if (
        new Date(PreferredCallDatesStart) >= new Date(PreferredCallDatesEnd)
      ) {
        setError("PreferredCallDatesStart", {
          type: "manual",
          message: "Invalid date",
        })
      } else {
        clearErrors("PreferredCallDatesStart")
      }
    }
  }, [PreferredCallDatesStart, PreferredCallDatesEnd, setError, clearErrors])

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      sx={{
        "&.MuiModal-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          boxShadow:
            "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
        }}
      >
        <Box
          borderRadius="0.5rem 0.5rem 0 0"
          bgcolor="#053747"
          height="10px"
        ></Box>
        <Box
          bgcolor="white"
          px="24px"
          pt="20px"
          pb="1rem"
          borderRadius="0 0 0.5rem 0.5rem"
          width="712px"
          fontFamily="14px"
        >
          <Stack direction="row" justifyContent={"space-between"} pb={"10px"}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              fontSize="20px"
              fontWeight={600}
            >
              New expert request
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />

          {/* RelatesTo & DeliveryType */}
          <Stack flexDirection="row" gap="16px" pt={"1rem"}>
            <Stack flexGrow={1} maxWidth={"50%"}>
              <Controller
                control={control}
                name="RelatesTo"
                defaultValue={currentPage}
                render={({field}) => (
                  <Input
                    label="Relates to"
                    required
                    error={errors.RelatesTo?.message}
                    textFieldProps={{
                      disabled: true,
                      id: "subject",
                      placeholder: "Relates to",
                      sx: {
                        ".MuiOutlinedInput-input": {
                          backgroundColor: "#BDBDBC",
                        },
                      },
                      InputProps: {
                        sx: {
                          fontSize: "14px",
                          ".MuiOutlinedInput-input.Mui-disabled": {
                            backgroundColor: "#BDBDBC",
                            color: "#242D35",
                            opacity: "unset",
                            "-webkit-text-fill-color": "#242D35",
                          },
                        },
                      },
                      ...field,
                    }}
                  />
                )}
              />
            </Stack>
            <Stack flexGrow={1}>
              <Controller
                control={control}
                name="DeliveryType"
                render={({field}) => (
                  <Select
                    selectFieldProps={{
                      required: true,
                      fullWidth: true,
                      MenuProps: {
                        sx: {
                          ".MuiPaper-root": {
                            backgroundColor: "white",
                          },
                        },
                      },
                      sx: {
                        fontSize: "14px",
                      },
                      ...field,
                    }}
                    error={errors.DeliveryType?.message}
                    formGroupProps={{}}
                    label="Delivery type"
                    required
                    placeholder="Select an option"
                  >
                    {deliveryType.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.type}
                        sx={{fontSize: "14px"}}
                      >
                        <Stack
                          gap={"8px"}
                          direction={"row"}
                          alignItems={"center"}
                        >
                          {<option.icon />}
                          {option.type}
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Stack>
          </Stack>
          {/* Description */}
          <Stack>
            <Controller
              control={control}
              name="Description"
              render={({field}) => (
                <RichTextField
                  formError={errors.Description?.message}
                  {...field}
                  onChange={(value) => {
                    setDescriptionHtmlContent(value.html)
                    field.onChange(value.text)
                  }}
                  placeholder="Enter description"
                  label="Description"
                />
              )}
            />
          </Stack>
          {/* Timezone & Request budget */}
          <Stack flexDirection="row" gap="26px" alignItems={"start"}>
            <Stack flexGrow={1} maxWidth={"50%"}>
              <Controller
                control={control}
                name="Timezone"
                defaultValue={getBrowserTimeZoneDescription()}
                render={({field}) => (
                  <Select
                    selectFieldProps={{
                      fullWidth: true,
                      MenuProps: {
                        sx: {".MuiPaper-root": {backgroundColor: "white"}},
                      },
                      sx: {fontSize: "14px"},
                      ...field,
                    }}
                    formGroupProps={{}}
                    label="Delivery time zone"
                    required
                  >
                    {generateSupportedTimeZones().map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option}
                        sx={{fontSize: "14px"}}
                      >
                        <Stack
                          gap={"8px"}
                          direction={"row"}
                          alignItems={"center"}
                        >
                          {option}
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Stack>
            <Stack flexGrow={1}>
              <Controller
                control={control}
                name="RequestBudget"
                render={({field}) => (
                  <Stack>
                    <Stack direction="row" gap={"8px"}>
                      <InputLabel
                        sx={{
                          color: errors.RequestBudget?.message
                            ? theme.palette.error.main
                            : theme.palette.primary.main,
                          fontWeight: 400,
                          fontSize: "0.875rem",
                        }}
                      >
                        Request budget
                      </InputLabel>
                      <Tooltip
                        title={
                          <Stack alignItems={"start"} pr={5}>
                            <Typography>
                              Indicative only - subject to
                            </Typography>
                            <Typography>scoping</Typography>
                          </Stack>
                        }
                        arrow
                        placement="right"
                        sx={{".MuiTooltip-tooltip": {bgcolor: "#053747"}}}
                      >
                        <Stack>
                          <InfoIcon />
                        </Stack>
                      </Tooltip>
                    </Stack>
                    <Slider
                      {...field}
                      value={Number(field.value) || 1500}
                      onChange={(_, value) => field.onChange(value)}
                      defaultValue={1500}
                      min={1500}
                      max={55000}
                      step={Number(field.value) <= 10000 ? 500 : 1000}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) =>
                        value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      slotProps={{
                        thumb: {
                          style: {
                            backgroundColor: "#FFF",
                            border: `2px solid ${theme.palette.primary.main}`,
                          },
                        },
                      }}
                      sx={{
                        ".MuiSlider-valueLabel": {
                          bgcolor: theme.palette.primary.main,
                          borderRadius: "5px",
                          py: "6px",
                        },
                      }}
                    />
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      sx={{
                        color: theme.palette.info.contrastText,
                      }}
                    >
                      <Typography fontSize={"14px"}>USD 1,500</Typography>
                      <Typography fontSize={"14px"}>USD 55,000</Typography>
                    </Stack>
                  </Stack>
                )}
              />
            </Stack>
          </Stack>
          {/* PreferredCallOrPresentation & BusinessPhone */}
          <Stack flexDirection="row" gap="16px" alignItems={"start"} pt="16px">
            <Stack flexGrow={1} maxWidth={"50%"} gap={"0.25rem"}>
              <Stack direction={"row"} alignItems={"center"}>
                <Stack direction={"row"} pr={"5px"}>
                  <Typography
                    color={
                      errors.PreferredCallDatesStart?.message ||
                      errors.PreferredCallDatesEnd?.message
                        ? "#DB0D00"
                        : "#053747"
                    }
                    fontSize={"0.875rem"}
                  >
                    Preferred call or presentation dates
                  </Typography>
                  {deliveryTypesWithRequiredDate.includes(
                    watch("DeliveryType")?.toLocaleLowerCase(),
                  ) && (
                    <span
                      style={{
                        color: "#DB0D00",
                        fontSize: "0.875rem",
                      }}
                    >
                      &nbsp; *
                    </span>
                  )}
                </Stack>
                <Tooltip
                  title={
                    <Stack alignItems={"start"} pr={5}>
                      <Typography>We will do our best to</Typography>
                      <Typography>accomodate your request</Typography>
                    </Stack>
                  }
                  arrow
                  placement="right"
                  sx={{".MuiTooltip-tooltip": {bgcolor: "#053747"}}}
                >
                  <Stack>
                    <InfoIcon />
                  </Stack>
                </Tooltip>
              </Stack>
              <Stack direction={"row"} gap={"4px"}>
                <Stack flexGrow={1} maxWidth={"50%"}>
                  <Controller
                    control={control}
                    name="PreferredCallDatesStart"
                    render={({field}) => (
                      <Stack>
                        <CustomDatePicker
                          {...field}
                          error={errors.PreferredCallDatesStart?.message}
                          minDate={new Date()}
                          maxDate={
                            watch("PreferredCallDatesEnd")
                              ? new Date(watch("PreferredCallDatesEnd") || "")
                              : undefined
                          }
                        />
                      </Stack>
                    )}
                  />
                </Stack>
                <Stack flexGrow={1} maxWidth={"50%"}>
                  <Controller
                    control={control}
                    name="PreferredCallDatesEnd"
                    render={({field}) => (
                      <Stack>
                        <CustomDatePicker
                          {...field}
                          error={errors.PreferredCallDatesEnd?.message}
                          minDate={
                            watch("PreferredCallDatesStart")
                              ? new Date(watch("PreferredCallDatesStart") || "")
                              : undefined
                          }
                        />
                      </Stack>
                    )}
                  />
                </Stack>
              </Stack>
              {!!errors.PreferredCallDatesEnd?.message &&
                !errors.PreferredCallDatesStart?.message && (
                  <ErrorMessage
                    message={errors.PreferredCallDatesEnd.message}
                  />
                )}
              {!!errors.PreferredCallDatesStart?.message && (
                <ErrorMessage
                  message={errors.PreferredCallDatesStart.message}
                />
              )}
            </Stack>
            <Stack flexGrow={1} pt={"1px"}>
              <Controller
                control={control}
                name="BusinessPhone"
                render={({field}) => (
                  <Input
                    label="Business phone"
                    required
                    error={errors.BusinessPhone?.message}
                    textFieldProps={{
                      id: "subject",
                      placeholder: "+0 000 0000 0000",
                      sx: {
                        ".MuiInputBase-input": {
                          p: "8.5px 14px",
                          borderRadius: "4px",
                        },
                      },
                      InputProps: {
                        sx: {
                          fontSize: "14px",
                        },
                      },
                      ...field,
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>

          <Stack
            flexDirection="row"
            gap={"5px"}
            justifyContent="end"
            pt="50px"
            px="8px"
          >
            <NewButton
              variant="outline"
              color="primary"
              size="medium"
              text="Cancel"
              onClick={handleClose}
              sx={{
                width: "88px",
                backgroundColor: "white",
                color: "#053747",
                textDecoration: "underline",
                textUnderlineOffset: "5px",
                border: "unset",
                "&:hover": {
                  borderBottomWidth: "2px",
                  bgcolor: "transparent",
                  paddingTop: "2px",
                  textDecoration: "underline",
                },
                "&:active": {
                  backgroundColor: "transparent",
                },
              }}
            />
            <NewButton
              variant="filled"
              color="primary"
              size="medium"
              text="Submit"
              type="submit"
              loading={!!sendNotificationMutation.isPending}
              sx={{
                width: "136px",
              }}
            />
          </Stack>
        </Box>
      </Stack>
    </Modal>
  )
}
