import {FC, SVGProps} from "react"

type IconProps = SVGProps<SVGSVGElement>

export const DashboardIcon: FC<IconProps> = (props) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_9421_68249)">
      <g clipPath="url(#clip1_9421_68249)">
        <path
          d="M18.7502 20H1.90805C1.03436 20 0.329102 19.2947 0.329102 18.4211V1.57895C0.329102 0.705263 1.03436 0 1.90805 0H18.7502C19.6238 0 20.3291 0.705263 20.3291 1.57895V18.4211C20.3291 19.2947 19.6238 20 18.7502 20ZM1.90805 1.05263C1.61331 1.05263 1.38173 1.28421 1.38173 1.57895V18.4211C1.38173 18.7158 1.61331 18.9474 1.90805 18.9474H18.7502C19.0449 18.9474 19.2765 18.7158 19.2765 18.4211V1.57895C19.2765 1.28421 19.0449 1.05263 18.7502 1.05263H1.90805Z"
          fill="#595958"
        />
        <path
          d="M7.17113 16.8421H5.06587C4.77113 16.8421 4.53955 16.6105 4.53955 16.3158V6.84211C4.53955 6.54737 4.77113 6.3158 5.06587 6.3158H7.17113C7.46587 6.3158 7.69745 6.54737 7.69745 6.84211V16.3158C7.69745 16.6105 7.46587 16.8421 7.17113 16.8421ZM5.59218 15.7895H6.64481V7.36843H5.59218V15.7895Z"
          fill="#595958"
        />
        <path
          d="M11.3816 16.842H9.27632C8.98158 16.842 8.75 16.6105 8.75 16.3157V3.68415C8.75 3.38942 8.98158 3.15784 9.27632 3.15784H11.3816C11.6763 3.15784 11.9079 3.38942 11.9079 3.68415V16.3157C11.9079 16.6105 11.6763 16.842 11.3816 16.842ZM9.80263 15.7894H10.8553V4.21047H9.80263V15.7894Z"
          fill="#595958"
        />
        <path
          d="M15.592 16.8422H13.4868C13.192 16.8422 12.9604 16.6106 12.9604 16.3158V11.0527C12.9604 10.7579 13.192 10.5264 13.4868 10.5264H15.592C15.8868 10.5264 16.1183 10.7579 16.1183 11.0527V16.3158C16.1183 16.6106 15.8868 16.8422 15.592 16.8422ZM14.0131 15.7895H15.0657V11.579H14.0131V15.7895Z"
          fill="#595958"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_9421_68249">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.329102)"
        />
      </clipPath>
      <clipPath id="clip1_9421_68249">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.329102)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const FileIcon: FC<IconProps> = (props) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.4968 20C8.01677 20 5.99677 17.98 5.99677 15.5V3.5C5.99677 1.57 7.56677 0 9.49677 0C11.4268 0 12.9968 1.57 12.9968 3.5V14.5C12.9968 15.88 11.8768 17 10.4968 17C9.11677 17 7.99677 15.88 7.99677 14.5V8.5C7.99677 8.22 8.21677 8 8.49677 8C8.77677 8 8.99677 8.22 8.99677 8.5V14.5C8.99677 15.33 9.66677 16 10.4968 16C11.3268 16 11.9968 15.33 11.9968 14.5V3.5C11.9968 2.12 10.8768 1 9.49677 1C8.11677 1 6.99677 2.12 6.99677 3.5V15.5C6.99677 17.43 8.56677 19 10.4968 19C12.4268 19 13.9968 17.43 13.9968 15.5V8.5C13.9968 8.22 14.2168 8 14.4968 8C14.7768 8 14.9968 8.22 14.9968 8.5V15.5C14.9968 17.98 12.9768 20 10.4968 20Z"
      fill="#7E8081"
    />
  </svg>
)

export const FolderIcon: FC<IconProps> = (props) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 15"
    height="20"
    width="20"
    {...props}
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        className="cls-1"
        strokeWidth="0px"
        d="m18.5,2h-7.5c-.09,0-.24-.09-.28-.17l-.55-1.11c-.21-.41-.71-.72-1.17-.72H2c-.46,0-.96.31-1.17.72L.28,1.83c-.16.31-.28.82-.28,1.17v10.5c0,.83.67,1.5,1.5,1.5h17c.83,0,1.5-.67,1.5-1.5V3.5c0-.83-.67-1.5-1.5-1.5Zm.5,11.5c0,.28-.22.5-.5.5H1.5c-.28,0-.5-.22-.5-.5V3c0-.2.08-.55.17-.72l.55-1.11c.04-.08.19-.17.28-.17h7c.09,0,.24.09.28.17l.55,1.11c.21.41.71.72,1.17.72h7.5c.28,0,.5.22.5.5v10Z"
      />
    </g>
  </svg>
)

export const AccessDeniedIcon: FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="62"
    height="44"
    viewBox="0 0 62 44"
    fill="none"
    {...props}
  >
    <path
      d="M22.2416 34.8827H22.3103C24.6675 36.9777 27.7356 38.0936 30.8875 38.0027C34.0399 37.9118 37.0387 36.6211 39.2712 34.3938C41.5039 32.1665 42.8017 29.1709 42.9001 26.0189C42.9985 22.867 41.8897 19.7963 39.8003 17.434V17.3653H39.7316C37.3909 15.1734 34.2909 13.9759 31.0845 14.0249C27.8782 14.0737 24.8162 15.3653 22.5436 17.6274C20.271 19.8895 18.9649 22.9454 18.9009 26.1513C18.8369 29.3574 20.0198 32.4631 22.2005 34.8138L22.2416 34.8827ZM31.0003 35.749C28.9271 35.743 26.9114 35.0679 25.2528 33.824L38.7003 20.3765C40.1269 22.2929 40.7899 24.6712 40.5602 27.0491C40.3305 29.427 39.2247 31.6347 37.4578 33.2426C35.691 34.8505 33.3893 35.7439 31.0003 35.749ZM31.0003 16.499C33.0735 16.505 35.0892 17.1801 36.7478 18.424L23.3003 31.8715C21.8737 29.9551 21.2107 27.5768 21.4404 25.1989C21.6701 22.821 22.7759 20.6133 24.5428 19.0054C26.3096 17.3975 28.6113 16.5041 31.0003 16.499Z"
      fill="#E83C4E"
    />
    <path
      d="M59.875 0H2.12501C1.76042 0 1.41067 0.144804 1.15283 0.402831C0.894804 0.660643 0.75 1.01042 0.75 1.37501V42.625C0.75 42.9896 0.894804 43.3393 1.15283 43.5972C1.41064 43.8552 1.76042 44 2.12501 44H59.875C60.2396 44 60.5893 43.8552 60.8472 43.5972C61.1052 43.3394 61.25 42.9896 61.25 42.625V1.37501C61.25 1.01042 61.1052 0.660671 60.8472 0.402831C60.5894 0.144804 60.2396 0 59.875 0ZM14.5 4.125C14.8646 4.125 15.2143 4.2698 15.4722 4.52783C15.7302 4.78564 15.875 5.13541 15.875 5.50001C15.875 5.8646 15.7302 6.21435 15.4722 6.47219C15.2144 6.73021 14.8646 6.87502 14.5 6.87502C14.1354 6.87502 13.7857 6.73021 13.5278 6.47219C13.2698 6.21438 13.125 5.8646 13.125 5.50001C13.125 5.13541 13.2698 4.78567 13.5278 4.52783C13.7856 4.2698 14.1354 4.125 14.5 4.125ZM10.375 4.125C10.7396 4.125 11.0893 4.2698 11.3472 4.52783C11.6052 4.78564 11.75 5.13541 11.75 5.50001C11.75 5.8646 11.6052 6.21435 11.3472 6.47219C11.0894 6.73021 10.7396 6.87502 10.375 6.87502C10.0104 6.87502 9.66067 6.73021 9.40283 6.47219C9.1448 6.21438 9 5.8646 9 5.50001C9 5.13541 9.1448 4.78567 9.40283 4.52783C9.66064 4.2698 10.0104 4.125 10.375 4.125ZM6.25001 4.125C6.6146 4.125 6.96435 4.2698 7.22219 4.52783C7.48022 4.78564 7.62502 5.13541 7.62502 5.50001C7.62502 5.8646 7.48022 6.21435 7.22219 6.47219C6.96438 6.73021 6.6146 6.87502 6.25001 6.87502C5.88541 6.87502 5.53567 6.73021 5.27783 6.47219C5.0198 6.21438 4.875 5.8646 4.875 5.50001C4.875 5.13541 5.0198 4.78567 5.27783 4.52783C5.53564 4.2698 5.88541 4.125 6.25001 4.125ZM58.5 41.25H3.50001V11H58.5V41.25Z"
      fill="#E83C4E"
    />
  </svg>
)

export const UnexpectedErrorIcon: FC<IconProps> = (props) => (
  <svg
    width="62"
    height="44"
    viewBox="0 0 62 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M59.875 0H2.12501C1.76042 0 1.41067 0.144804 1.15283 0.402831C0.894804 0.660643 0.75 1.01042 0.75 1.37501V42.625C0.75 42.9896 0.894804 43.3393 1.15283 43.5972C1.41064 43.8552 1.76042 44 2.12501 44H59.875C60.2396 44 60.5893 43.8552 60.8472 43.5972C61.1052 43.3394 61.25 42.9896 61.25 42.625V1.37501C61.25 1.01042 61.1052 0.660671 60.8472 0.402831C60.5894 0.144804 60.2396 0 59.875 0ZM14.5 4.125C14.8646 4.125 15.2143 4.2698 15.4722 4.52783C15.7302 4.78564 15.875 5.13541 15.875 5.50001C15.875 5.8646 15.7302 6.21435 15.4722 6.47219C15.2144 6.73021 14.8646 6.87502 14.5 6.87502C14.1354 6.87502 13.7857 6.73021 13.5278 6.47219C13.2698 6.21438 13.125 5.8646 13.125 5.50001C13.125 5.13541 13.2698 4.78567 13.5278 4.52783C13.7856 4.2698 14.1354 4.125 14.5 4.125ZM10.375 4.125C10.7396 4.125 11.0893 4.2698 11.3472 4.52783C11.6052 4.78564 11.75 5.13541 11.75 5.50001C11.75 5.8646 11.6052 6.21435 11.3472 6.47219C11.0894 6.73021 10.7396 6.87502 10.375 6.87502C10.0104 6.87502 9.66067 6.73021 9.40283 6.47219C9.1448 6.21438 9 5.8646 9 5.50001C9 5.13541 9.1448 4.78567 9.40283 4.52783C9.66064 4.2698 10.0104 4.125 10.375 4.125ZM6.25001 4.125C6.6146 4.125 6.96435 4.2698 7.22219 4.52783C7.48022 4.78564 7.62502 5.13541 7.62502 5.50001C7.62502 5.8646 7.48022 6.21435 7.22219 6.47219C6.96438 6.73021 6.6146 6.87502 6.25001 6.87502C5.88541 6.87502 5.53567 6.73021 5.27783 6.47219C5.0198 6.21438 4.875 5.8646 4.875 5.50001C4.875 5.13541 5.0198 4.78567 5.27783 4.52783C5.53564 4.2698 5.88541 4.125 6.25001 4.125ZM58.5 41.25H3.50001V11H58.5V41.25Z"
      fill="#E83C4E"
    />
    <path
      d="M19.7995 35.8565L19.7996 35.8565L19.796 35.8504C19.7361 35.7506 19.732 35.6169 19.7995 35.4968L30.6857 16.1437L30.4695 16.0221L30.6857 16.1437C30.7477 16.0334 30.8552 15.9688 31 15.9688C31.136 15.9688 31.2498 16.0382 31.3162 16.1471L42.2005 35.4968C42.2659 35.6132 42.2665 35.7369 42.2022 35.8534C42.1342 35.9645 42.0195 36.0315 41.8982 36.0315H41.874H20.1018C19.9719 36.0315 19.8629 35.9692 19.7995 35.8565ZM20.9241 34.9494L20.7147 35.3219H21.142H40.8459H41.2735L41.0638 34.9493L31.2058 17.4347L30.9878 17.0474L30.77 17.4348L20.9241 34.9494Z"
      fill="#E83C4E"
      stroke="#E83C4E"
      strokeWidth="0.5"
    />
    <path
      d="M30.9878 29.9835C30.7872 29.9835 30.6331 29.8293 30.6331 29.6287V23.5809C30.6331 23.3803 30.7872 23.2261 30.9878 23.2261C31.1885 23.2261 31.3426 23.3803 31.3426 23.5809V29.6287C31.3426 29.8293 31.1885 29.9835 30.9878 29.9835Z"
      fill="#E83C4E"
      stroke="#E83C4E"
      strokeWidth="0.5"
    />
    <path
      d="M30.9878 33.6125C30.7872 33.6125 30.6331 33.4583 30.6331 33.2577V32.0481C30.6331 31.8475 30.7872 31.6934 30.9878 31.6934C31.1885 31.6934 31.3426 31.8475 31.3426 32.0481V33.2577C31.3426 33.4583 31.1885 33.6125 30.9878 33.6125Z"
      fill="#E83C4E"
      stroke="#E83C4E"
      strokeWidth="0.5"
    />
  </svg>
)

export const ErrorIcon: FC<IconProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37264 5.37264 0 12 0C18.6274 0 24 5.37264 24 12C24 18.6274 18.6274 24 12 24C5.37264 24 0 18.6274 0 12ZM12 2.4C6.69816 2.4 2.4 6.69816 2.4 12C2.4 17.3018 6.69816 21.6 12 21.6C17.3018 21.6 21.6 17.3018 21.6 12C21.6 6.69816 17.3018 2.4 12 2.4Z"
      fill="#DB0D00"
    />
    <path
      d="M16.7999 8.24762L15.7525 7.2002L11.5999 11.3528L7.44733 7.2002L6.3999 8.24762L10.5525 12.4002L6.3999 16.5528L7.44733 17.6002L11.5999 13.4476L15.7525 17.6002L16.7999 16.5528L12.6473 12.4002L16.7999 8.24762Z"
      fill="#DB0D00"
    />
  </svg>
)

export const SuccessIcon: FC<IconProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_9227_1706)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 5.37264 5.37264 0 12 0C18.6274 0 24 5.37264 24 12C24 18.6274 18.6274 24 12 24C5.37264 24 0 18.6274 0 12ZM12 2.4C6.69816 2.4 2.4 6.69816 2.4 12C2.4 17.3018 6.69816 21.6 12 21.6C17.3018 21.6 21.6 17.3018 21.6 12C21.6 6.69816 17.3018 2.4 12 2.4Z"
        fill="#58A733"
      />
      <g clipPath="url(#clip1_9227_1706)">
        <path
          d="M6.82017 11.6183L6.75249 11.5561L6.68481 11.6183L5.69208 12.5311L5.61202 12.6047L5.69208 12.6783L9.66299 16.3291L9.73067 16.3914L9.79836 16.3291L18.3074 8.50586L18.3875 8.43225L18.3074 8.35863L17.3147 7.44592L17.247 7.38369L17.1794 7.44592L9.73067 14.2943L6.82017 11.6183Z"
          fill="#58A733"
          stroke="#58A733"
          strokeWidth="0.2"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_9227_1706">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_9227_1706">
        <rect
          width="12.8"
          height="12.8"
          fill="white"
          transform="translate(5.59961 5.59961)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const SearchIcon: FC<IconProps> = (props) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 20"
    {...props}
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        className="cls-1"
        strokeWidth="0px"
        d="m18.87,19.16l-5.94-6.48c1.34-1.4,2.08-3.23,2.08-5.18s-.78-3.89-2.2-5.3c-1.42-1.42-3.3-2.2-5.3-2.2S3.61.78,2.2,2.2C.78,3.61,0,5.5,0,7.5s.78,3.89,2.2,5.3,3.3,2.2,5.3,2.2c1.73,0,3.36-.58,4.69-1.64l5.94,6.48c.1.11.23.16.37.16.12,0,.24-.04.34-.13.2-.19.22-.5.03-.71h0ZM1,7.5C1,3.92,3.92,1,7.5,1s6.5,2.92,6.5,6.5-2.92,6.5-6.5,6.5S1,11.08,1,7.5Z"
      />
    </g>
  </svg>
)

export const ArrowDropDownIcon: FC<IconProps> = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 3L6 9L12 3H0Z" fill="#595958" />
  </svg>
)

export const ChevronDownIcon: FC<IconProps> = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8978_36293)">
      <path
        d="M0.398926 4.89902C0.398926 4.79502 0.438926 4.69102 0.518926 4.61902C0.670926 4.45902 0.926926 4.45902 1.07893 4.61902L7.99893 11.539L14.9189 4.61902C15.0709 4.45902 15.3269 4.45902 15.4869 4.61902C15.6389 4.77102 15.6389 5.02702 15.4869 5.18702L8.28693 12.387C8.13493 12.539 7.87893 12.539 7.71893 12.387L0.518926 5.17902C0.438926 5.09902 0.398926 4.99502 0.398926 4.89902Z"
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_8978_36293">
        <rect
          width="15.202"
          height="8.002"
          fill="white"
          transform="translate(0.398926 4.49902)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const ChevronUpIcon: FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill={props.fill}
    {...props}
  >
    <path
      d="M0 6.8351C0 6.91716 0.0315623 6.99922 0.094687 7.05603C0.214624 7.18228 0.416623 7.18228 0.542872 7.05603L5.99684 1.60206L11.4571 7.06235C11.5834 7.18859 11.7791 7.18859 11.9053 7.06235C12.0316 6.94241 12.0316 6.74041 11.9053 6.61416L6.21778 0.93294C6.09784 0.813003 5.89584 0.813003 5.7696 0.93294L0.094687 6.61416C0.0315623 6.67728 0 6.75935 0 6.8351Z"
      fill={props.fill}
    />
  </svg>
)

export const PlusIcon: FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.fill}
  >
    <path
      d="M19.5789 11.5789H12.4211V4.42105C12.4211 4.18526 12.2358 4 12 4C11.7642 4 11.5789 4.18526 11.5789 4.42105V11.5789H4.42105C4.18526 11.5789 4 11.7642 4 12C4 12.2358 4.18526 12.4211 4.42105 12.4211H11.5789V19.5789C11.5789 19.8147 11.7642 20 12 20C12.2358 20 12.4211 19.8147 12.4211 19.5789V12.4211H19.5789C19.8147 12.4211 20 12.2358 20 12C20 11.7642 19.8147 11.5789 19.5789 11.5789Z"
      fill={props.fill}
    />
  </svg>
)

export const UploadIcon: FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.fill}
  >
    <path
      d="M18.7368 18.5263H5.26316C4.56421 18.5263 4 17.9621 4 17.2632V15.5789C4 15.3432 4.18526 15.1579 4.42105 15.1579C4.65684 15.1579 4.84211 15.3432 4.84211 15.5789V17.2632C4.84211 17.4989 5.02737 17.6842 5.26316 17.6842H18.7368C18.9726 17.6842 19.1579 17.4989 19.1579 17.2632V15.5789C19.1579 15.3432 19.3432 15.1579 19.5789 15.1579C19.8147 15.1579 20 15.3432 20 15.5789V17.2632C20 17.9621 19.4358 18.5263 18.7368 18.5263Z"
      fill={props.fill}
    />
    <path
      d="M16.5052 9.81053L12.2946 5.6C12.1346 5.43158 11.8652 5.43158 11.6967 5.6L7.48621 9.81053C7.32621 9.97053 7.32621 10.24 7.48621 10.4084C7.64621 10.5684 7.91568 10.5684 8.08411 10.4084L11.5788 6.91369V15.5789C11.5788 15.8147 11.7641 16 11.9999 16C12.2357 16 12.4209 15.8147 12.4209 15.5789V6.91369L15.9157 10.4084C15.9999 10.4926 16.1094 10.5347 16.2104 10.5347C16.3115 10.5347 16.4294 10.4926 16.5052 10.4084C16.6652 10.24 16.6652 9.97895 16.5052 9.81053Z"
      fill={props.fill}
    />
  </svg>
)

export const CheckIcon: FC<IconProps> = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.4 18.4971C8.296 18.4971 8.192 18.4571 8.12 18.3771L4.12 14.3771C3.96 14.2251 3.96 13.9691 4.12 13.8091C4.28 13.6491 4.528 13.6571 4.688 13.8091L8.408 17.5291L19.312 6.61705C19.472 6.46505 19.72 6.46505 19.88 6.61705C20.04 6.77705 20.04 7.02505 19.88 7.18505L8.68 18.3771C8.6 18.4571 8.496 18.4971 8.4 18.4971Z"
      fill="#053747"
    />
  </svg>
)

export const PlusAltIcon: FC<IconProps> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_9038_14011)">
      <path
        d="M13.6589 2.34105C12.1516 0.833684 10.1389 0 8 0C5.86105 0 3.85684 0.833684 2.34105 2.34105C0.833684 3.85684 0 5.86105 0 8C0 10.1389 0.833684 12.1432 2.34105 13.6589C3.84842 15.1663 5.86105 16 8 16C10.1389 16 12.1432 15.1663 13.6589 13.6589C15.1747 12.1516 16 10.1389 16 8C16 5.86105 15.1663 3.85684 13.6589 2.34105ZM8 15.1579C4.05053 15.1579 0.842105 11.9495 0.842105 8C0.842105 4.05053 4.05053 0.842105 8 0.842105C11.9495 0.842105 15.1579 4.05053 15.1579 8C15.1579 11.9495 11.9495 15.1579 8 15.1579Z"
        fill="#242D35"
      />
      <path
        d="M13.0527 7.579H8.4211V2.94742C8.4211 2.71163 8.23584 2.52637 8.00005 2.52637C7.76426 2.52637 7.579 2.71163 7.579 2.94742V7.579H2.94742C2.71163 7.579 2.52637 7.76426 2.52637 8.00005C2.52637 8.23584 2.71163 8.4211 2.94742 8.4211H7.579V13.0527C7.579 13.2885 7.76426 13.4737 8.00005 13.4737C8.23584 13.4737 8.4211 13.2885 8.4211 13.0527V8.4211H13.0527C13.2885 8.4211 13.4737 8.23584 13.4737 8.00005C13.4737 7.76426 13.2885 7.579 13.0527 7.579Z"
        fill="#242D35"
      />
    </g>
    <defs>
      <clipPath id="clip0_9038_14011">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const CancelIcon: FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="33"
    viewBox="0 0 16 33"
    fill={props.fill}
  >
    <path
      d="M16 10.1114L14.3886 8.5L8 14.8886L1.61143 8.5L0 10.1114L6.38857 16.5L0 22.8886L1.61143 24.5L8 18.1114L14.3886 24.5L16 22.8886L9.61143 16.5L16 10.1114Z"
      fill={props.fill}
    />
  </svg>
)

export const MinusAltIcon: FC<IconProps> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_9776_44672)">
      <path
        d="M13.6589 2.34105C12.1516 0.833684 10.1389 0 8 0C5.86105 0 3.85684 0.833684 2.34105 2.34105C0.833684 3.85684 0 5.86105 0 8C0 10.1389 0.833684 12.1432 2.34105 13.6589C3.84842 15.1663 5.86105 16 8 16C10.1389 16 12.1432 15.1663 13.6589 13.6589C15.1747 12.1516 16 10.1389 16 8C16 5.86105 15.1663 3.85684 13.6589 2.34105ZM8 15.1579C4.05053 15.1579 0.842105 11.9495 0.842105 8C0.842105 4.05053 4.05053 0.842105 8 0.842105C11.9495 0.842105 15.1579 4.05053 15.1579 8C15.1579 11.9495 11.9495 15.1579 8 15.1579Z"
        fill="#242D35"
      />
      <path
        d="M13.0527 8.42096H2.94742C2.71163 8.42096 2.52637 8.2357 2.52637 7.99991C2.52637 7.76412 2.71163 7.57886 2.94742 7.57886H13.0527C13.2885 7.57886 13.4737 7.76412 13.4737 7.99991C13.4737 8.2357 13.2885 8.42096 13.0527 8.42096Z"
        fill="#242D35"
      />
    </g>
    <defs>
      <clipPath id="clip0_9776_44672">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const LoadingIcon: FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={props.fill}
  >
    <path
      d="M8 0C5.86353 0 3.85882 0.828235 2.34353 2.34353C0.828235 3.85882 0 5.86353 0 8C0 10.1365 0.828235 12.1412 2.34353 13.6565C3.84941 15.1718 5.86353 16 8 16C10.1365 16 12.1412 15.1718 13.6565 13.6565C15.1718 12.1506 16 10.1365 16 8C16 7.73647 15.7929 7.52941 15.5294 7.52941C15.2659 7.52941 15.0588 7.73647 15.0588 8C15.0588 11.8965 11.8965 15.0588 8 15.0588C4.10353 15.0588 0.941176 11.8965 0.941176 8C0.941176 4.10353 4.11294 0.865882 8 0.941176C8.18824 0.941176 8.56471 0.837647 8.56471 0.461176C8.56471 0.0847059 8.17882 0 8 0Z"
      fill={props.fill}
    />
  </svg>
)

export const QuestionIcon: FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="0 0 24 24"
    fill={props.fill}
  >
    <path
      d="M17.6589 6.34105C16.1516 4.83368 14.1389 4 12 4C9.86105 4 7.85684 4.83368 6.34105 6.34105C4.83368 7.85684 4 9.86105 4 12C4 14.1389 4.83368 16.1432 6.34105 17.6589C7.84842 19.1663 9.86105 20 12 20C14.1389 20 16.1432 19.1663 17.6589 17.6589C19.1747 16.1516 20 14.1389 20 12C20 9.86105 19.1663 7.85684 17.6589 6.34105ZM12 19.1579C8.05053 19.1579 4.84211 15.9495 4.84211 12C4.84211 8.05053 8.05053 4.84211 12 4.84211C15.9495 4.84211 19.1579 8.05053 19.1579 12C19.1579 15.9495 15.9495 19.1579 12 19.1579Z"
      fill={props.fill}
    />
    <path
      d="M12.0451 14.4436C11.8556 14.4436 11.7068 14.2947 11.7068 14.1053V12.7519C11.7068 12.5624 11.8556 12.4135 12.0451 12.4135C13.3511 12.4135 14.4135 11.3511 14.4135 10.0451C14.4135 8.7391 13.3511 7.67669 12.0451 7.67669C10.7391 7.67669 9.67669 8.7391 9.67669 10.0451C9.67669 10.2346 9.52782 10.3835 9.33835 10.3835C9.14887 10.3835 9 10.2346 9 10.0451C9 8.36692 10.3669 7 12.0451 7C13.7233 7 15.0902 8.36692 15.0902 10.0451C15.0902 11.6083 13.906 12.9007 12.3835 13.0699V14.1053C12.3835 14.2947 12.2346 14.4436 12.0451 14.4436Z"
      fill={props.fill}
    />
    <path
      d="M12.0454 16.4737C11.8559 16.4737 11.707 16.3249 11.707 16.1354V15.4587C11.707 15.2692 11.8559 15.1204 12.0454 15.1204C12.2349 15.1204 12.3837 15.2692 12.3837 15.4587V16.1354C12.3837 16.3249 12.2349 16.4737 12.0454 16.4737Z"
      fill={props.fill}
    />
  </svg>
)

export const WarningIcon: FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill={props.fill}
  >
    <path
      d="M13.619 13.2582H0.36629C0.233763 13.2582 0.115961 13.192 0.0496976 13.0742C-0.0165659 12.9637 -0.0165659 12.8238 0.0496976 12.706L6.67604 0.925856C6.74231 0.808054 6.86011 0.741791 7 0.741791C7.13989 0.741791 7.25769 0.815417 7.32395 0.925856L13.9503 12.706C14.0166 12.8238 14.0166 12.9564 13.9503 13.0742C13.884 13.1846 13.7662 13.2582 13.6337 13.2582H13.619ZM0.999474 12.522H12.9932L6.99264 1.86091L0.999474 12.522Z"
      fill={props.fill}
    />
    <path
      d="M6.99258 9.57694C6.78643 9.57694 6.62445 9.41497 6.62445 9.20881V5.52751C6.62445 5.32136 6.78643 5.15938 6.99258 5.15938C7.19873 5.15938 7.36071 5.32136 7.36071 5.52751V9.20881C7.36071 9.41497 7.19873 9.57694 6.99258 9.57694Z"
      fill={props.fill}
    />
    <path
      d="M6.99258 11.7857C6.78643 11.7857 6.62445 11.6237 6.62445 11.4176V10.6813C6.62445 10.4751 6.78643 10.3132 6.99258 10.3132C7.19873 10.3132 7.36071 10.4751 7.36071 10.6813V11.4176C7.36071 11.6237 7.19873 11.7857 6.99258 11.7857Z"
      fill={props.fill}
    />
  </svg>
)

export const BinIcon: FC<IconProps> = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.7998 6.1H14.1998L13.1198 4.66C13.0478 4.556 12.9278 4.5 12.7998 4.5H11.1998C11.0718 4.5 10.9518 4.556 10.8798 4.66L9.7998 6.1H5.1998C4.9758 6.1 4.7998 6.276 4.7998 6.5C4.7998 6.724 4.9758 6.9 5.1998 6.9H18.7998C19.0238 6.9 19.1998 6.724 19.1998 6.5C19.1998 6.276 19.0238 6.1 18.7998 6.1ZM11.3998 5.3H12.5998L13.1998 6.1H10.7998L11.3998 5.3Z"
      fill="#DB0D00"
    />
    <path
      d="M18.0634 7.70757C17.8474 7.67557 17.6394 7.81957 17.5994 8.03557L15.7274 19.2436C15.6874 19.4836 15.4394 19.6996 15.1914 19.6996H8.79944C8.55144 19.6996 8.30344 19.4836 8.26344 19.2436L6.39144 8.03557C6.35944 7.81957 6.15144 7.66757 5.92744 7.70757C5.70344 7.74757 5.55944 7.94757 5.59944 8.17157L7.47144 19.3796C7.57544 20.0116 8.15944 20.4996 8.79944 20.4996H15.1994C15.8394 20.4996 16.4234 20.0036 16.5274 19.3796L18.3994 8.17157C18.4314 7.95557 18.2874 7.74757 18.0714 7.70757H18.0634Z"
      fill="#DB0D00"
    />
  </svg>
)

export const DownloadIcon: FC<IconProps> = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5051 11.7842C16.3451 11.6242 16.0756 11.6242 15.9072 11.7842L12.4125 15.2789V5.76315C12.4125 5.52737 12.2272 5.3421 11.9914 5.3421C11.7556 5.3421 11.5704 5.52737 11.5704 5.76315V15.2705L8.07562 11.7758C7.91562 11.6158 7.64615 11.6158 7.47773 11.7758C7.31773 11.9442 7.31773 12.2053 7.47773 12.3737L11.6883 16.5842C11.7725 16.6684 11.8819 16.7105 11.983 16.7105C12.084 16.7105 12.2019 16.6684 12.2777 16.5842L16.4883 12.3737C16.6483 12.2053 16.6483 11.9442 16.4883 11.7758L16.5051 11.7842Z"
      fill="#595958"
    />
    <path
      d="M18.7368 19.6579H5.26316C4.56421 19.6579 4 19.0937 4 18.3948V16.7105C4 16.4748 4.18526 16.2895 4.42105 16.2895C4.65684 16.2895 4.84211 16.4748 4.84211 16.7105V18.3948C4.84211 18.6305 5.02737 18.8158 5.26316 18.8158H18.7368C18.9726 18.8158 19.1579 18.6305 19.1579 18.3948V16.7105C19.1579 16.4748 19.3432 16.2895 19.5789 16.2895C19.8147 16.2895 20 16.4748 20 16.7105V18.3948C20 19.0937 19.4358 19.6579 18.7368 19.6579Z"
      fill="#595958"
    />
  </svg>
)

export const CloseIcon: FC<IconProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5976 11.9968L19.8686 4.71742C20.0369 4.55753 20.0369 4.28823 19.8686 4.11992C19.7087 3.96003 19.4394 3.96003 19.2711 4.11992L11.9917 11.3993L4.72069 4.12834C4.5608 3.96003 4.2915 3.96003 4.1316 4.12834C3.97171 4.29665 3.96329 4.55753 4.1316 4.71742L11.411 11.9968L4.1316 19.2763C3.96329 19.4362 3.96329 19.7055 4.1316 19.8738C4.21576 19.9579 4.32516 20 4.42615 20C4.52713 20 4.64495 19.9579 4.72069 19.8738L12.0001 12.5943L19.2795 19.8738C19.3637 19.9579 19.4731 20 19.5741 20C19.6751 20 19.7929 19.9579 19.8686 19.8738C20.0285 19.7055 20.0285 19.4446 19.8686 19.2763L12.5892 11.9968H12.5976Z"
      fill={props.fill || "#6A6A69"}
    />
  </svg>
)

export const PresentationIcon: FC<IconProps> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_268_54150)">
      <path
        d="M14.8 0.800049H1.2C0.5384 0.800049 0 1.33845 0 2.00005V12.4C0 13.0616 0.5384 13.6 1.2 13.6H5.3528L4.8 14.7056C4.6736 14.9592 4.5416 15.1392 4.4728 15.2136C4.3016 15.2592 4.1752 15.4152 4.1752 15.6C4.1752 15.8208 4.3544 16 4.5752 16H11.424C11.6448 16 11.824 15.8208 11.824 15.6C11.824 15.4144 11.6976 15.2584 11.5264 15.2136C11.4576 15.14 11.3264 14.9592 11.1992 14.7056L10.6464 13.6H14.7992C15.4608 13.6 15.9992 13.0616 15.9992 12.4V2.00005C15.9992 1.33845 15.4616 0.800049 14.8 0.800049ZM1.2 1.60005H14.8C15.0208 1.60005 15.2 1.77925 15.2 2.00005V9.60005H0.8V2.00005C0.8 1.77925 0.9792 1.60005 1.2 1.60005ZM10.4848 15.0632C10.508 15.1096 10.532 15.1552 10.5568 15.2H5.4448C5.4696 15.1552 5.4936 15.1096 5.5168 15.0632L6.2488 13.6H9.7544L10.4864 15.0632H10.4848ZM14.8 12.8H1.2C0.9792 12.8 0.8 12.6208 0.8 12.4V10.4H15.2V12.4C15.2 12.6208 15.0208 12.8 14.8 12.8Z"
        fill="#053747"
      />
      <path
        d="M8.39995 12H7.59995C7.37915 12 7.19995 11.8208 7.19995 11.6C7.19995 11.3792 7.37915 11.2 7.59995 11.2H8.39995C8.62075 11.2 8.79995 11.3792 8.79995 11.6C8.79995 11.8208 8.62075 12 8.39995 12Z"
        fill="#053747"
      />
    </g>
    <defs>
      <clipPath id="clip0_268_54150">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const TrainingIcon: FC<IconProps> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_268_54162)">
      <path
        d="M15.0832 3.7167C14.476 3.1087 13.0944 2.3999 11.2 2.3999C9.5584 2.3999 8.3016 2.9335 7.6 3.4711C6.8984 2.9335 5.6416 2.3999 4 2.3999C2.1064 2.3999 0.7248 3.1095 0.1168 3.7167C0.0416 3.7919 0 3.8935 0 3.9999V12.7999C0 12.9615 0.0976 13.1079 0.2472 13.1695C0.3968 13.2311 0.5688 13.1975 0.6832 13.0831C1.0856 12.6807 2.2368 11.9999 4 11.9999C5.7632 11.9999 6.9152 12.6807 7.3168 13.0831C7.4728 13.2391 7.7264 13.2391 7.8824 13.0831C8.2848 12.6807 9.436 11.9999 11.1992 11.9999C12.9624 11.9999 14.1144 12.6807 14.516 13.0831C14.5928 13.1599 14.6944 13.1999 14.7992 13.1999C14.8504 13.1999 14.9024 13.1903 14.952 13.1695C15.1016 13.1079 15.1992 12.9615 15.1992 12.7999V3.9999C15.1992 3.8935 15.1576 3.7919 15.0832 3.7167ZM0.8 12.0031V4.1767C1.2832 3.7719 2.3848 3.2007 4 3.2007C5.6152 3.2007 6.7176 3.7719 7.2 4.1775V12.0039C6.4656 11.5703 5.3664 11.2007 4 11.2007C2.6336 11.2007 1.5344 11.5695 0.8 12.0039V12.0031ZM14.4 12.0031C13.6656 11.5695 12.5656 11.1999 11.2 11.1999C9.8344 11.1999 8.7344 11.5695 8 12.0031V4.1767C8.4824 3.7719 9.584 3.1999 11.2 3.1999C12.816 3.1999 13.9168 3.7711 14.4 4.1759V12.0031Z"
        fill="#053747"
      />
    </g>
    <defs>
      <clipPath id="clip0_268_54162">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const PhoneCallIcon: FC<IconProps> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_268_54182)">
      <path
        d="M12.8001 16C11.3833 16 9.8761 15.5984 8.3201 14.8064C6.8857 14.076 5.4689 13.0304 4.2225 11.7824C2.9761 10.5344 1.9321 9.116 1.2025 7.6808C0.412098 6.124 0.010498 4.6168 0.010498 3.2C0.010498 2.2816 0.866498 1.3944 1.2337 1.056C1.7625 0.5688 2.5945 0 3.1993 0C3.5001 0 3.8521 0.1968 4.3089 0.6192C4.6489 0.9344 5.0321 1.3616 5.4153 1.8544C5.6465 2.152 6.7993 3.6712 6.7993 4.4C6.7993 4.9976 6.1233 5.4136 5.4081 5.8528C5.1313 6.0224 4.8457 6.1984 4.6393 6.364C4.4185 6.5408 4.3793 6.6344 4.3729 6.6552C5.1321 8.548 7.4529 10.8688 9.3449 11.6272C9.3617 11.6216 9.4553 11.5848 9.6353 11.3608C9.8009 11.1544 9.9769 10.868 10.1465 10.592C10.5865 9.8768 11.0017 9.2008 11.5993 9.2008C12.3281 9.2008 13.8473 10.3536 14.1449 10.5848C14.6377 10.968 15.0649 11.3512 15.3801 11.6912C15.8025 12.1472 15.9993 12.5 15.9993 12.8008C15.9993 13.4056 15.4305 14.24 14.9441 14.7712C14.6049 15.1408 13.7177 16.0008 12.7993 16.0008L12.8001 16ZM3.1953 0.8C2.9809 0.804 2.4041 1.0664 1.7769 1.644C1.1817 2.1928 0.811298 2.7888 0.811298 3.2C0.811298 8.5832 7.4225 15.2 12.8001 15.2C13.2105 15.2 13.8065 14.828 14.3553 14.2296C14.9337 13.5992 15.1961 13.02 15.2001 12.8048C15.1745 12.6528 14.7537 12.0616 13.6025 11.1752C12.6129 10.4136 11.8105 10.0048 11.6041 10C11.5897 10.004 11.5001 10.0384 11.3185 10.2688C11.1609 10.4696 10.9921 10.744 10.8281 11.0096C10.3801 11.7384 9.9569 12.4272 9.3417 12.4272C9.2425 12.4272 9.1449 12.408 9.0513 12.3704C6.9513 11.5304 4.4681 9.0472 3.6281 6.9472C3.5273 6.6952 3.5113 6.3 4.0073 5.8504C4.2713 5.6112 4.6361 5.3872 4.9897 5.1696C5.2553 5.0064 5.5305 4.8376 5.7305 4.6792C5.9609 4.4976 5.9953 4.408 5.9993 4.3936C5.9937 4.1872 5.5857 3.3848 4.8241 2.3952C3.9377 1.244 3.3465 0.824 3.1945 0.7976L3.1953 0.8Z"
        fill="#053747"
      />
    </g>
    <defs>
      <clipPath id="clip0_268_54182">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const OtherIcon: FC<IconProps> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_268_54186)">
      <path
        d="M15.6008 14.4C14.528 14.4 13.4128 13.7152 13.0024 13.4352C12.552 13.544 12.0816 13.6 11.6 13.6C10.4504 13.6 9.36395 13.2832 8.54235 12.7072C7.67675 12.1016 7.19995 11.2816 7.19995 10.4C7.19995 9.51835 7.67675 8.69835 8.54235 8.09275C9.36475 7.51755 10.4504 7.19995 11.6 7.19995C12.7496 7.19995 13.836 7.51675 14.6576 8.09275C15.5232 8.69835 16 9.51835 16 10.4C16 11.1712 15.6312 11.9016 14.9552 12.4776C15.0208 12.6624 15.2264 13.0592 15.8712 13.7056C15.9504 13.7784 16 13.8832 16 14C16 14.2208 15.8216 14.4 15.6008 14.4ZM13.0824 12.6016C13.1688 12.6016 13.2536 12.6296 13.324 12.6824C13.332 12.688 13.864 13.0856 14.5264 13.356C14.0912 12.72 14.1024 12.3576 14.1416 12.2056C14.1648 12.1184 14.216 12.0416 14.288 11.9872C14.876 11.5456 15.1992 10.9824 15.1992 10.4C15.1992 9.78635 14.844 9.19995 14.1984 8.74795C13.5096 8.26555 12.5864 7.99995 11.5992 7.99995C10.612 7.99995 9.68875 8.26555 8.99995 8.74795C8.35435 9.19995 7.99915 9.78635 7.99915 10.4C7.99915 11.0136 8.35435 11.6 8.99995 12.052C9.68875 12.5344 10.612 12.8 11.5992 12.8C12.0752 12.8 12.5376 12.7384 12.9736 12.616C13.0088 12.6064 13.0456 12.6016 13.0816 12.6016H13.0824Z"
        fill="#053747"
      />
      <path
        d="M0.399897 15.1999C0.219897 15.1999 0.0622968 15.0799 0.0142968 14.9063C-0.0337032 14.7327 0.0398968 14.5487 0.194297 14.4567C1.5471 13.6455 2.1079 12.4655 2.3071 11.9135C0.836697 10.7879 -0.000103231 9.23032 -0.000103231 7.59992C-0.000103231 6.77672 0.206297 5.97912 0.614297 5.22872C1.0031 4.51352 1.5583 3.87272 2.2631 3.32392C3.6927 2.21192 5.5879 1.59912 7.5999 1.59912C9.4223 1.59912 11.1847 2.11432 12.5615 3.05032C13.9495 3.99352 14.8583 5.29912 15.1207 6.72632C15.1607 6.94392 15.0167 7.15192 14.7999 7.19192C14.5831 7.23192 14.3743 7.08792 14.3343 6.87112C14.1111 5.65592 13.3215 4.53432 12.1119 3.71192C10.8663 2.86552 9.2639 2.39912 7.5999 2.39912C3.8503 2.39912 0.799897 4.73192 0.799897 7.59912C0.799897 9.05112 1.6039 10.4487 3.0047 11.4327C3.1423 11.5295 3.2047 11.7015 3.1615 11.8639C3.0679 12.2127 2.7495 13.1703 1.8975 14.0943C2.9391 13.7295 4.0607 13.0967 4.8887 12.5271C4.9871 12.4591 5.1103 12.4391 5.2255 12.4719C5.9839 12.6887 6.7831 12.7991 7.5999 12.7991C7.8207 12.7991 7.9999 12.9783 7.9999 13.1991C7.9999 13.4199 7.8207 13.5991 7.5999 13.5991C6.7735 13.5991 5.9623 13.4959 5.1871 13.2911C4.8439 13.5199 4.2023 13.9255 3.4359 14.3071C2.2455 14.8991 1.2247 15.1991 0.400697 15.1991L0.399897 15.1999Z"
        fill="#053747"
      />
    </g>
    <defs>
      <clipPath id="clip0_268_54186">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const WebResportIcon: FC<IconProps> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1668 2.8999H12.3668C12.1428 2.8999 11.9668 3.0759 11.9668 3.2999C11.9668 3.5239 12.1428 3.6999 12.3668 3.6999H13.1668C13.3908 3.6999 13.5668 3.8759 13.5668 4.0999V15.2999C13.5668 15.5239 13.3908 15.6999 13.1668 15.6999H1.96685C1.74285 15.6999 1.56685 15.5239 1.56685 15.2999V4.0999C1.56685 3.8759 1.74285 3.6999 1.96685 3.6999H2.76685C2.99085 3.6999 3.16685 3.5239 3.16685 3.2999C3.16685 3.0759 2.99085 2.8999 2.76685 2.8999H1.96685C1.30285 2.8999 0.766846 3.4359 0.766846 4.0999V15.2999C0.766846 15.9639 1.30285 16.4999 1.96685 16.4999H13.1668C13.8308 16.4999 14.3668 15.9639 14.3668 15.2999V4.0999C14.3668 3.4359 13.8308 2.8999 13.1668 2.8999Z"
      fill="#053747"
    />
    <path
      d="M4.3668 4.5H10.7668C10.9908 4.5 11.1668 4.324 11.1668 4.1C11.1668 4.1 11.1668 4.084 11.1668 4.076C11.1668 3.284 10.7988 2.668 10.1428 2.34C9.9188 2.228 9.7028 2.172 9.5348 2.14C9.3668 1.212 8.5508 0.5 7.5668 0.5C6.5828 0.5 5.7668 1.204 5.5988 2.14C5.4228 2.172 5.2068 2.236 4.9908 2.34C4.3348 2.668 3.9668 3.292 3.9668 4.1C3.9668 4.324 4.1428 4.5 4.3668 4.5ZM5.3268 3.068C5.6388 2.908 5.9668 2.9 5.9668 2.9C6.1908 2.9 6.3668 2.724 6.3668 2.5C6.3668 1.836 6.9028 1.3 7.5668 1.3C8.2308 1.3 8.7668 1.836 8.7668 2.5C8.7668 2.724 8.9428 2.9 9.1668 2.9C9.1668 2.9 9.5028 2.9 9.8068 3.068C10.0708 3.204 10.2388 3.412 10.3188 3.7H4.8148C4.8948 3.412 5.0628 3.204 5.3268 3.068Z"
      fill="#053747"
    />
    <path
      d="M7.56699 2.1001C7.46299 2.1001 7.35899 2.1401 7.28699 2.2201C7.21499 2.2921 7.16699 2.3961 7.16699 2.5001C7.16699 2.6041 7.21499 2.7081 7.28699 2.7801C7.35899 2.8521 7.46299 2.9001 7.56699 2.9001C7.67099 2.9001 7.77499 2.8601 7.84699 2.7801C7.91899 2.7081 7.96699 2.6041 7.96699 2.5001C7.96699 2.3961 7.92699 2.2921 7.84699 2.2201C7.77499 2.1481 7.67099 2.1001 7.56699 2.1001Z"
      fill="#053747"
    />
    <path
      d="M7.69499 5.69995H7.67899H7.42299C5.03099 5.78795 3.16699 7.72395 3.16699 10.116C3.16699 12.508 4.96699 14.356 7.30299 14.524H7.36699C7.43899 14.5319 7.51899 14.54 7.59099 14.54C7.64699 14.54 7.70299 14.54 7.75899 14.54H7.85499C10.191 14.396 12.015 12.46 12.015 10.124C12.015 7.78795 10.079 5.76395 7.70299 5.70795L7.69499 5.69995ZM5.90299 6.96395C5.26299 7.71595 4.87099 8.66795 4.79099 9.69995H4.03099C4.16699 8.53195 4.88699 7.50795 5.90299 6.96395ZM4.03099 10.54H4.79899C4.89499 11.604 5.31899 12.572 5.99899 13.324C4.93499 12.796 4.17499 11.756 4.03099 10.54ZM7.15899 13.204C6.31899 12.58 5.76699 11.62 5.64699 10.54H7.15899V13.204ZM7.15899 9.69195H5.63899C5.74299 8.58795 6.29499 7.60395 7.15899 6.96395V9.69195ZM8.00699 7.01195C8.83099 7.65195 9.35899 8.61995 9.47099 9.69195H8.00699V7.01195ZM8.00699 13.156V10.532H9.46299C9.34299 11.58 8.81499 12.532 8.00699 13.156ZM9.06299 13.364C9.76699 12.604 10.207 11.604 10.303 10.54H11.127C10.975 11.78 10.175 12.852 9.06299 13.364ZM10.311 9.69195C10.231 8.63595 9.81499 7.65995 9.15099 6.89995C10.223 7.42795 10.983 8.46795 11.127 9.69195H10.311Z"
      fill="#053747"
    />
  </svg>
)

export const ListOrdered: FC<IconProps> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5 4.3676H5.5C5.22 4.3676 5 4.19391 5 3.97286C5 3.75181 5.22 3.57812 5.5 3.57812H19.5C19.78 3.57812 20 3.75181 20 3.97286C20 4.19391 19.78 4.3676 19.5 4.3676Z"
      fill="inherit"
    />
    <path
      d="M19.5 9.89494H5.5C5.22 9.89494 5 9.72126 5 9.50021C5 9.27915 5.22 9.10547 5.5 9.10547H19.5C19.78 9.10547 20 9.27915 20 9.50021C20 9.72126 19.78 9.89494 19.5 9.89494Z"
      fill="inherit"
    />
    <path
      d="M19.5 15.4203H5.5C5.22 15.4203 5 15.2466 5 15.0256C5 14.8045 5.22 14.6309 5.5 14.6309H19.5C19.78 14.6309 20 14.8045 20 15.0256C20 15.2466 19.78 15.4203 19.5 15.4203Z"
      fill="inherit"
    />
    <path
      d="M1.5 5.94737C1.22 5.94737 1 5.77368 1 5.55263V2.78947H0.5C0.22 2.78947 0 2.61579 0 2.39474C0 2.17368 0.22 2 0.5 2H1.5C1.78 2 2 2.17368 2 2.39474V5.55263C2 5.77368 1.78 5.94737 1.5 5.94737Z"
      fill="inherit"
    />
    <path
      d="M2.5 11.4728H0.5C0.22 11.4728 0 11.2991 0 11.078V9.49907C0 9.27802 0.22 9.10434 0.5 9.10434H2V8.31486H0.5C0.22 8.31486 0 8.14118 0 7.92013C0 7.69907 0.22 7.52539 0.5 7.52539H2.5C2.78 7.52539 3 7.69907 3 7.92013V9.49907C3 9.72013 2.78 9.89381 2.5 9.89381H1V10.6833H2.5C2.78 10.6833 3 10.857 3 11.078C3 11.2991 2.78 11.4728 2.5 11.4728Z"
      fill="inherit"
    />
    <path
      d="M2.5 13.0527H0.5C0.22 13.0527 0 13.2264 0 13.4475C0 13.6685 0.22 13.8422 0.5 13.8422H2V14.6317H0.5C0.22 14.6317 0 14.8054 0 15.0264C0 15.2475 0.22 15.4212 0.5 15.4212H2V16.2106H0.5C0.22 16.2106 0 16.3843 0 16.6054C0 16.8264 0.22 17.0001 0.5 17.0001H2.5C2.78 17.0001 3 16.8264 3 16.6054V13.4475C3 13.2264 2.78 13.0527 2.5 13.0527Z"
      fill="inherit"
    />
  </svg>
)

export const ListBulleted: FC<IconProps> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5 15.5H4.5C4.22 15.5 4 15.28 4 15C4 14.72 4.22 14.5 4.5 14.5H19.5C19.78 14.5 20 14.72 20 15C20 15.28 19.78 15.5 19.5 15.5Z"
      fill="inherit"
    />
    <path
      d="M19.5 10.5H4.5C4.22 10.5 4 10.28 4 10C4 9.72 4.22 9.5 4.5 9.5H19.5C19.78 9.5 20 9.72 20 10C20 10.28 19.78 10.5 19.5 10.5Z"
      fill="inherit"
    />
    <path
      d="M19.5 5.5H4.5C4.22 5.5 4 5.28 4 5C4 4.72 4.22 4.5 4.5 4.5H19.5C19.78 4.5 20 4.72 20 5C20 5.28 19.78 5.5 19.5 5.5Z"
      fill="inherit"
    />
    <path
      d="M1.5 6.5C0.67 6.5 0 5.83 0 5C0 4.17 0.67 3.5 1.5 3.5C2.33 3.5 3 4.17 3 5C3 5.83 2.33 6.5 1.5 6.5ZM1.5 4.5C1.22 4.5 1 4.72 1 5C1 5.28 1.22 5.5 1.5 5.5C1.78 5.5 2 5.28 2 5C2 4.72 1.78 4.5 1.5 4.5Z"
      fill="inherit"
    />
    <path
      d="M1.5 11.5C0.67 11.5 0 10.83 0 10C0 9.17 0.67 8.5 1.5 8.5C2.33 8.5 3 9.17 3 10C3 10.83 2.33 11.5 1.5 11.5ZM1.5 9.5C1.22 9.5 1 9.72 1 10C1 10.28 1.22 10.5 1.5 10.5C1.78 10.5 2 10.28 2 10C2 9.72 1.78 9.5 1.5 9.5Z"
      fill="inherit"
    />
    <path
      d="M1.5 16.5C0.67 16.5 0 15.83 0 15C0 14.17 0.67 13.5 1.5 13.5C2.33 13.5 3 14.17 3 15C3 15.83 2.33 16.5 1.5 16.5ZM1.5 14.5C1.22 14.5 1 14.72 1 15C1 15.28 1.22 15.5 1.5 15.5C1.78 15.5 2 15.28 2 15C2 14.72 1.78 14.5 1.5 14.5Z"
      fill="inherit"
    />
  </svg>
)

export const FontItalic: FC<IconProps> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 2H11.499H11.495H7.5C7.224 2 7 2.224 7 2.5C7 2.776 7.224 3 7.5 3H10.89L8.09 17H4.5C4.224 17 4 17.224 4 17.5C4 17.776 4.224 18 4.5 18H8.499H8.501H8.503H12.5C12.776 18 13 17.776 13 17.5C13 17.224 12.776 17 12.5 17H9.11L11.91 3H15.5C15.776 3 16 2.776 16 2.5C16 2.224 15.776 2 15.5 2Z"
      fill="inherit"
    />
  </svg>
)

export const FontBold: FC<IconProps> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 9H7.5C7.224 9 7 8.776 7 8.5V5.5C7 5.224 7.224 5 7.5 5H10C11.103 5 12 5.897 12 7C12 8.103 11.103 9 10 9ZM8 8H10C10.551 8 11 7.551 11 7C11 6.449 10.551 6 10 6H8V8Z"
      fill="inherit"
    />
    <path
      d="M11 15H7.5C7.224 15 7 14.776 7 14.5V11.5C7 11.224 7.224 11 7.5 11H11C12.103 11 13 11.897 13 13C13 14.103 12.103 15 11 15ZM8 14H11C11.551 14 12 13.551 12 13C12 12.449 11.551 12 11 12H8V14Z"
      fill="inherit"
    />
    <path
      d="M11 18H5.5C4.673 18 4 17.327 4 16.5V3.5C4 2.673 4.673 2 5.5 2H10C12.757 2 15 4.243 15 7C15 7.824 14.798 8.628 14.413 9.349C15.426 10.294 16 11.602 16 13C16 15.757 13.757 18 11 18ZM5.5 3C5.224 3 5 3.224 5 3.5V16.5C5 16.776 5.224 17 5.5 17H11C13.206 17 15 15.206 15 13C15 11.763 14.439 10.615 13.461 9.849C13.25 9.684 13.208 9.383 13.361 9.167C13.779 8.521 14 7.772 14 7C14 4.794 12.206 3 10 3H5.5Z"
      fill="inherit"
    />
  </svg>
)

export const FontUnderline: FC<IconProps> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5 18H5.5C5.224 18 5 17.776 5 17.5C5 17.224 5.224 17 5.5 17H14.5C14.776 17 15 17.224 15 17.5C15 17.776 14.776 18 14.5 18Z"
      fill="inherit"
    />
    <path
      d="M10 15C7.243 15 5 12.757 5 10V2.5C5 2.224 5.224 2 5.5 2C5.776 2 6 2.224 6 2.5V10C6 12.206 7.794 14 10 14C12.206 14 14 12.206 14 10V2.5C14 2.224 14.224 2 14.5 2C14.776 2 15 2.224 15 2.5V10C15 12.757 12.757 15 10 15Z"
      fill="inherit"
    />
  </svg>
)

export const InfoIcon: FC<IconProps> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_812_852" fill="white">
      <path d="M13.6589 2.34105C12.1516 0.833684 10.1389 0 8 0C5.86105 0 3.85684 0.833684 2.34105 2.34105C0.833684 3.85684 0 5.86105 0 8C0 10.1389 0.833684 12.1432 2.34105 13.6589C3.84842 15.1663 5.86105 16 8 16C10.1389 16 12.1432 15.1663 13.6589 13.6589C15.1747 12.1516 16 10.1389 16 8C16 5.86105 15.1663 3.85684 13.6589 2.34105ZM8 15.1579C4.05053 15.1579 0.842105 11.9495 0.842105 8C0.842105 4.05053 4.05053 0.842105 8 0.842105C11.9495 0.842105 15.1579 4.05053 15.1579 8C15.1579 11.9495 11.9495 15.1579 8 15.1579Z" />
    </mask>
    <path
      d="M13.6589 2.34105C12.1516 0.833684 10.1389 0 8 0C5.86105 0 3.85684 0.833684 2.34105 2.34105C0.833684 3.85684 0 5.86105 0 8C0 10.1389 0.833684 12.1432 2.34105 13.6589C3.84842 15.1663 5.86105 16 8 16C10.1389 16 12.1432 15.1663 13.6589 13.6589C15.1747 12.1516 16 10.1389 16 8C16 5.86105 15.1663 3.85684 13.6589 2.34105ZM8 15.1579C4.05053 15.1579 0.842105 11.9495 0.842105 8C0.842105 4.05053 4.05053 0.842105 8 0.842105C11.9495 0.842105 15.1579 4.05053 15.1579 8C15.1579 11.9495 11.9495 15.1579 8 15.1579Z"
      fill="#053747"
    />
    <path
      d="M13.6589 2.34105L14.368 1.63592L14.3661 1.63395L13.6589 2.34105ZM2.34105 2.34105L1.63591 1.63197L1.63198 1.63592L2.34105 2.34105ZM2.34105 13.6589L1.63198 14.3641L1.63395 14.3661L2.34105 13.6589ZM14.3661 1.63395C12.6708 -0.0612921 10.4037 -1 8 -1V1C9.8742 1 11.6323 1.72866 12.9518 3.04816L14.3661 1.63395ZM8 -1C5.59471 -1 3.33759 -0.0602405 1.63592 1.63198L3.04619 3.05013C4.37609 1.72761 6.1274 1 8 1V-1ZM1.63198 1.63592C-0.0602405 3.33759 -1 5.59471 -1 8H1C1 6.1274 1.72761 4.37609 3.05013 3.04619L1.63198 1.63592ZM-1 8C-1 10.4053 -0.0602404 12.6624 1.63198 14.3641L3.05013 12.9538C1.72761 11.6239 1 9.8726 1 8H-1ZM1.63395 14.3661C3.32918 16.0613 5.59631 17 8 17V15C6.1258 15 4.36766 14.2713 3.04816 12.9518L1.63395 14.3661ZM8 17C10.4053 17 12.6624 16.0602 14.3641 14.368L12.9538 12.9499C11.6239 14.2724 9.8726 15 8 15V17ZM14.3641 14.368C16.0713 12.6703 17 10.4013 17 8H15C15 9.87661 14.2781 11.6329 12.9538 12.9499L14.3641 14.368ZM17 8C17 5.59471 16.0602 3.33759 14.368 1.63592L12.9499 3.04619C14.2724 4.37609 15 6.1274 15 8H17ZM8 14.1579C4.60281 14.1579 1.84211 11.3972 1.84211 8H-0.157895C-0.157895 12.5018 3.49824 16.1579 8 16.1579V14.1579ZM1.84211 8C1.84211 4.60281 4.60281 1.84211 8 1.84211V-0.157895C3.49824 -0.157895 -0.157895 3.49824 -0.157895 8H1.84211ZM8 1.84211C11.3972 1.84211 14.1579 4.60281 14.1579 8H16.1579C16.1579 3.49824 12.5018 -0.157895 8 -0.157895V1.84211ZM14.1579 8C14.1579 11.3972 11.3972 14.1579 8 14.1579V16.1579C12.5018 16.1579 16.1579 12.5018 16.1579 8H14.1579Z"
      fill="#242D35"
      mask="url(#path-1-inside-1_812_852)"
    />
    <mask id="path-3-inside-2_812_852" fill="white">
      <path d="M8.00015 3.78748C8.23594 3.78748 8.42121 3.91379 8.42121 4.07379V4.638C8.42121 4.78958 8.23594 4.92432 8.00015 4.92432C7.76436 4.92432 7.5791 4.798 7.5791 4.638V4.07379C7.5791 3.92221 7.76436 3.78748 8.00015 3.78748Z" />
    </mask>
    <path
      d="M8.00015 3.78748C8.23594 3.78748 8.42121 3.91379 8.42121 4.07379V4.638C8.42121 4.78958 8.23594 4.92432 8.00015 4.92432C7.76436 4.92432 7.5791 4.798 7.5791 4.638V4.07379C7.5791 3.92221 7.76436 3.78748 8.00015 3.78748Z"
      fill="#053747"
    />
    <path
      d="M8.00015 4.78748C7.94945 4.78748 7.85127 4.77547 7.73648 4.69735C7.62229 4.61963 7.42121 4.4145 7.42121 4.07379H9.42121C9.42121 3.57308 9.12749 3.22479 8.86173 3.04392C8.59535 2.86264 8.28665 2.78748 8.00015 2.78748V4.78748ZM7.42121 4.07379V4.638H9.42121V4.07379H7.42121ZM7.42121 4.638C7.42121 4.28381 7.63211 4.08371 7.72871 4.01663C7.83709 3.94136 7.93547 3.92432 8.00015 3.92432V5.92432C8.30062 5.92432 8.60953 5.8399 8.8695 5.65937C9.11768 5.48703 9.42121 5.14377 9.42121 4.638H7.42121ZM8.00015 3.92432C8.05086 3.92432 8.14904 3.93632 8.26383 4.01445C8.37802 4.09216 8.5791 4.29729 8.5791 4.638H6.5791C6.5791 5.13871 6.87281 5.487 7.13858 5.66787C7.40496 5.84916 7.71366 5.92432 8.00015 5.92432V3.92432ZM8.5791 4.638V4.07379H6.5791V4.638H8.5791ZM8.5791 4.07379C8.5791 4.42798 8.3682 4.62808 8.2716 4.69516C8.16322 4.77043 8.06483 4.78748 8.00015 4.78748V2.78748C7.69968 2.78748 7.39077 2.87189 7.13081 3.05242C6.88263 3.22477 6.5791 3.56802 6.5791 4.07379H8.5791Z"
      fill="#242D35"
      mask="url(#path-3-inside-2_812_852)"
    />
    <path
      d="M8.00015 5.74329C8.23594 5.74329 8.42121 5.8696 8.42121 6.0296V11.6717C8.42121 11.8317 8.23594 11.958 8.00015 11.958C7.76436 11.958 7.5791 11.8317 7.5791 11.6717V6.0296C7.5791 5.87802 7.76436 5.74329 8.00015 5.74329Z"
      fill="#053747"
    />
  </svg>
)

export const CalendarIcon: FC<IconProps> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#595958"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.8 1.60005H12.8V1.20005C12.8 0.976049 12.624 0.800049 12.4 0.800049C12.176 0.800049 12 0.976049 12 1.20005V1.60005H4V1.20005C4 0.976049 3.824 0.800049 3.6 0.800049C3.376 0.800049 3.2 0.976049 3.2 1.20005V1.60005H1.2C0.536 1.60005 0 2.13605 0 2.80005V14C0 14.664 0.536 15.2 1.2 15.2H14.8C15.464 15.2 16 14.664 16 14V2.80005C16 2.13605 15.464 1.60005 14.8 1.60005ZM1.2 2.40005H3.2V3.60005C3.2 3.82405 3.376 4.00005 3.6 4.00005C3.824 4.00005 4 3.82405 4 3.60005V2.40005H12V3.60005C12 3.82405 12.176 4.00005 12.4 4.00005C12.624 4.00005 12.8 3.82405 12.8 3.60005V2.40005H14.8C15.024 2.40005 15.2 2.57605 15.2 2.80005V4.80005H0.8V2.80005C0.8 2.57605 0.976 2.40005 1.2 2.40005ZM14.8 14.4H1.2C0.976 14.4 0.8 14.224 0.8 14V5.60005H15.2V14C15.2 14.224 15.024 14.4 14.8 14.4Z"
      fill="#595958"
    />
  </svg>
)

export const LeftArrowIcon: FC<IconProps> = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.107882 4.73114L4.65026 0.188755C4.79411 0.0373421 5.03637 0.0373421 5.18778 0.188755C5.33919 0.332597 5.33919 0.574857 5.18778 0.72627L1.2889 4.61758H13.6215C13.8334 4.61758 14 4.78413 14 4.99611C14 5.20809 13.8334 5.37464 13.6215 5.37464H1.2889L5.18778 9.27352C5.33919 9.42493 5.33919 9.65962 5.18778 9.81103C5.11207 9.88674 5.01365 9.92459 4.92281 9.92459C4.83196 9.92459 4.72597 9.88674 4.65783 9.81103L0.107882 5.26108C-0.0359605 5.11724 -0.0359605 4.87498 0.107882 4.72357V4.73114Z"
      fill="#053747"
    />
  </svg>
)

export const RightArrowIcon: FC<IconProps> = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.8996 4.72731L9.3548 0.182469C9.21088 0.030974 8.96848 0.030974 8.81699 0.182469C8.66549 0.326389 8.66549 0.56878 8.81699 0.720275L12.718 4.62126H0.378737C0.166644 4.62126 0 4.78791 0 5C0 5.21209 0.166644 5.37874 0.378737 5.37874H12.718L8.81699 9.27972C8.66549 9.43122 8.66549 9.66603 8.81699 9.81753C8.89274 9.89328 8.99121 9.93115 9.0821 9.93115C9.173 9.93115 9.27905 9.89328 9.34722 9.81753L13.8921 5.27269C14.036 5.12877 14.036 4.88638 13.8921 4.73488L13.8996 4.72731Z"
      fill="#053747"
    />
  </svg>
)

export const FullScreenModeIcon: FC<IconProps> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_13569_16812)">
      <path
        d="M7.85398 7.146L1.70798 1H5.50098C5.77698 1 6.00098 0.776 6.00098 0.5C6.00098 0.224 5.77698 0 5.50098 0H0.500977C0.224977 0 0.000976562 0.224 0.000976562 0.5V5.5C0.000976562 5.776 0.224977 6 0.500977 6C0.776977 6 1.00098 5.776 1.00098 5.5V1.707L7.14698 7.853C7.24498 7.951 7.37298 7.999 7.50098 7.999C7.62898 7.999 7.75698 7.95 7.85498 7.853C8.04998 7.658 8.04898 7.341 7.85398 7.146Z"
        fill="#003D50"
      />
      <path
        d="M19.5002 0H14.5002C14.2242 0 14.0002 0.224 14.0002 0.5C14.0002 0.776 14.2242 1 14.5002 1H18.2932L12.1472 7.146C11.9522 7.341 11.9522 7.658 12.1472 7.853C12.2452 7.951 12.3732 7.999 12.5002 7.999C12.6272 7.999 12.7562 7.95 12.8532 7.853L19.0002 1.707V5.5C19.0002 5.776 19.2242 6 19.5002 6C19.7762 6 20.0002 5.776 20.0002 5.5V0.5C20.0002 0.224 19.7762 0 19.5002 0Z"
        fill="#003D50"
      />
      <path
        d="M7.85398 12.147C7.65898 11.952 7.34198 11.952 7.14698 12.147L1.00098 18.293V14.5C1.00098 14.224 0.776977 14 0.500977 14C0.224977 14 0.000976562 14.224 0.000976562 14.5V19.5C0.000976562 19.776 0.224977 20 0.500977 20H5.50098C5.77698 20 6.00098 19.776 6.00098 19.5C6.00098 19.224 5.77698 19 5.50098 19H1.70798L7.85398 12.853C8.04898 12.658 8.04898 12.341 7.85398 12.146V12.147Z"
        fill="#003D50"
      />
      <path
        d="M19.5002 14.0002C19.2242 14.0002 19.0002 14.2242 19.0002 14.5002V18.2932L12.8532 12.1472C12.6582 11.9522 12.3413 11.9522 12.1462 12.1472C11.9513 12.3422 11.9513 12.6592 12.1462 12.8542L18.2932 19.0002H14.5003C14.2243 19.0002 14.0003 19.2242 14.0003 19.5002C14.0003 19.7762 14.2243 20.0002 14.5003 20.0002H19.5002C19.7762 20.0002 20.0002 19.7762 20.0002 19.5002V14.5002C20.0002 14.2242 19.7762 14.0002 19.5002 14.0002Z"
        fill="#003D50"
      />
    </g>
    <defs>
      <clipPath id="clip0_13569_16812">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const PresentationModeIcon: FC<IconProps> = (props) => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.8 2.99961H10.4V0.999609C10.4 0.893523 10.3579 0.791781 10.2828 0.716767C10.2078 0.641752 10.1061 0.599609 10 0.599609C9.89391 0.599609 9.79217 0.641752 9.71716 0.716767C9.64214 0.791781 9.6 0.893523 9.6 0.999609V2.99961H1.2C0.881847 2.99996 0.576824 3.1265 0.351855 3.35147C0.126887 3.57643 0.000347278 3.88146 0 4.19961V16.1996C0.000347278 16.5178 0.126887 16.8228 0.351855 17.0478C0.576824 17.2727 0.881847 17.3993 1.2 17.3996H5.96777L3.2877 20.7497C3.25471 20.7907 3.23014 20.8378 3.21539 20.8883C3.20064 20.9388 3.19599 20.9917 3.20173 21.044C3.20746 21.0964 3.22346 21.147 3.2488 21.1931C3.27415 21.2392 3.30834 21.2799 3.34943 21.3128C3.39051 21.3456 3.43768 21.3701 3.48822 21.3847C3.53877 21.3993 3.5917 21.4038 3.64399 21.3979C3.69628 21.3921 3.7469 21.3759 3.79294 21.3505C3.83898 21.325 3.87955 21.2907 3.91231 21.2495L6.99224 17.3996H13.0078L16.0877 21.2495C16.1541 21.332 16.2505 21.3848 16.3557 21.3964C16.461 21.4079 16.5665 21.3772 16.6492 21.3111C16.7319 21.2449 16.785 21.1487 16.7968 21.0434C16.8086 20.9382 16.7782 20.8326 16.7123 20.7497L14.0322 17.3996H18.8C19.1182 17.3993 19.4232 17.2727 19.6481 17.0478C19.8731 16.8228 19.9997 16.5178 20 16.1996V4.19961C19.9997 3.88146 19.8731 3.57643 19.6481 3.35147C19.4232 3.1265 19.1182 2.99996 18.8 2.99961ZM19.2 16.1996C19.1999 16.3057 19.1577 16.4073 19.0827 16.4823C19.0077 16.5573 18.9061 16.5995 18.8 16.5996H1.2C1.09395 16.5995 0.992266 16.5573 0.917274 16.4823C0.842283 16.4073 0.800106 16.3057 0.8 16.1996V4.19961C0.800106 4.09356 0.842283 3.99188 0.917274 3.91688C0.992266 3.84189 1.09395 3.79972 1.2 3.79961H18.8C18.9061 3.79972 19.0077 3.84189 19.0827 3.91688C19.1577 3.99188 19.1999 4.09356 19.2 4.19961V16.1996Z"
      fill="#053747"
    />
  </svg>
)
