import {Stack, StackProps} from "@mui/material"
import {FC, PropsWithChildren} from "react"

const FormGroup: FC<PropsWithChildren<StackProps>> = (props) => {
  const {children, ...rest} = props

  return (
    <Stack
      direction="column"
      gap="0.25rem"
      flex={1}
      role="group"
      component="article"
      {...rest}
    >
      {children}
    </Stack>
  )
}

FormGroup.displayName = "FormGroup"

export default FormGroup
