import {type FC} from "react"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  useTheme,
  Typography,
  SxProps,
  Stack,
} from "@mui/material"
import {NewButton} from "../../atoms"
import {CloseIcon} from "../../../assets/icons"

type Props = {
  title: string
  message: string
  showDialog: boolean
  dialogProps?: DialogProps
  disabled?: boolean
  disabledClose?: boolean
  closeText?: string
  confirmText?: string
  handleClose: () => void
  handleConfirm: () => void
  closeStyle?: SxProps
  confirmStyle?: SxProps
}

const ConfirmationDialog: FC<Props> = (props) => {
  const {
    title,
    message,
    showDialog,
    dialogProps,
    disabled,
    disabledClose,
    closeText = "Cancel",
    confirmText = "Confirm",
    handleClose,
    handleConfirm,
    closeStyle,
    confirmStyle,
  } = props
  const theme = useTheme()
  return (
    // @ts-ignore
    <Dialog
      open={showDialog}
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{
        component: "dialog",
      }}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "704px",
          borderRadius: "0.25rem",
          paddingX: "0",
          paddingY: "1.5rem",
          gap: "1.5rem",
          backgroundColor: theme.palette.common.white,
          border: "none",
          borderTopWidth: "7px",
          borderTopStyle: "solid",
          borderTopColor: "#053747",
        },
      }}
      {...dialogProps}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        paddingX="2rem"
      >
        <DialogTitle
          sx={{
            fontSize: "1.25rem",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "1.5rem",
            padding: "0",
            color: "#242D35",
          }}
        >
          {title}
        </DialogTitle>
        <NewButton
          icon
          disableRipple
          variant="text"
          onClick={handleClose}
          IconLeft={<CloseIcon />}
          sx={{
            padding: "0",
            minWidth: "auto",
            height: "auto",
            width: "auto",

            svg: {
              width: "24px",
              height: "24px",
            },
          }}
        />
      </Stack>
      <DialogContent
        sx={{
          paddingTop: "1rem !important",
          paddingBottom: 0,
          paddingX: "2rem",
          marginBottom: "2rem",
          borderTop: "1px solid #CACDCD",
          fontSize: "1rem",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "1.5rem",
          color: "#242D35",
        }}
      >
        {message}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          gap: "1.5rem",
          paddingX: "2rem",
          paddingY: "0",
        }}
      >
        <Button
          onClick={handleClose}
          disabled={disabledClose}
          disableRipple
          sx={{
            backgroundColor: "transparent",
            minHeight: "0",
            textTransform: "none",
            padding: "0",
            paddingY: "2px",
            color: "#053747",
            borderRadius: "0",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: "#053747",

            "&:hover, &:active": {
              borderBottomWidth: "2px",
              backgroundColor: "transparent",
              p: {
                marginBottom: "-1px",
              },
            },

            "&:disabled": {
              color: "#9B9B9B",
              borderBottomColor: "transparent",
            },
            ...closeStyle,
          }}
        >
          <Typography
            sx={{
              color: "inherit",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "1.5rem",
            }}
          >
            {closeText}
          </Typography>
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={disabled}
          disableRipple
          sx={{
            backgroundColor: "#053747",
            color: "#FFFFFF",
            minHeight: "0",
            height: "40px",
            textTransform: "none",
            paddingX: "36.5px",
            paddingY: "0",
            borderRadius: "0.25rem",

            "&:hover": {
              backgroundColor: "#0A495D",
            },

            "&:active": {
              backgroundColor: "#0A5D81",
            },

            "&:disabled": {
              backgroundColor: "#EEEEEE",
              color: "#CACDCD",
            },
            ...confirmStyle,
          }}
        >
          <Typography
            sx={{
              color: "inherit",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "1.5rem",
            }}
          >
            {confirmText}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
